import React from 'react';
import StepForm from '../../components/step_form';
import { NcFormInputWithAddon, NcInput, NcRadioList } from '../../../../shared_components/neocamino/inputs';
import { If } from '../../helpers';

export default function ResourcesAndSkillsStep({ values, isAdmin, helpers, ...rest }) {
  const digitalAppetizeText =
    "Etes-vous à l’aise avec le digital (publier sur les réseaux sociaux, réaliser une newsletter ? " +
    "Que savez-vous / aimez-vous faire ?) Parlez-vous le SEO ? Le netlinking ? " +
    "Souhaitez-vous monter en compétences ? " +
    "Avez-vous la main sur votre site internet ? Aimez-vous rédiger ? "

  const freeTimeText =
  "Insister sur le temps (en fonction de son rôle dans l’entreprise ou si il est entrepreneur/ indépendant, " +
  "n’aura probablement pas le temps de mettre en œuvre ? Combien de temps par semaine dédiez-vous à votre communication ?"

  const adminInputs = (
    <>
      <NcInput
        name='digital_appetize'
        label={digitalAppetizeText}
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='com_budget'
        label="Budget dédié à votre communication / marketing ? Personne dédiée au sein de votre équipe ?"
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='free_time'
        label={freeTimeText}
        type='text'
        componentClass='textarea'
        className='admin'
      />
    </>
  );

  return (
    <StepForm name='Vos ressources et compétences' values={values} isAdmin={isAdmin} {...rest}>
      <NcInput name='account_attributes[id]' type='hidden' />

      <NcFormInputWithAddon name='account_attributes[annual_budget]' type="customNumber" label='Budget annuel' before={false}>
        €
      </NcFormInputWithAddon>

      <hr />

      <h4>Évaluez-vous sur les éléments ci-dessous :</h4>

      <NcRadioList
        name='will_eval_learning'
        type="radio"
        label='J’ai envie de monter en compétence et apprendre à faire :'
        options={helpers.will_evaluation_ranges}
      />

      <NcRadioList
        name='will_eval_communication'
        type="radio"
        label='Je peux dégager du temps pour travailler ma communication :'
        options={helpers.will_evaluation_ranges}
      />

      <NcRadioList
        name='will_eval_knowledge'
        type="radio"
        label='Je sais utiliser les outils digitaux (édition site, réseaux sociaux, pub, blog…) :'
        options={helpers.will_evaluation_ranges}
      />

      <NcRadioList
        name='will_eval_motivation'
        type="radio"
        label='Je suis prêt(e) à investir dans ma communication si j’ai une bonne stratégie :'
        options={helpers.will_evaluation_ranges}
      />

      <If condition={isAdmin} then={adminInputs} />
    </StepForm>
  );
}
