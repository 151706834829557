import { Row, Col } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import { Card } from './shared/styled_components';

const CardText = styled.div`
  position: relative;
  top: ${(props) => (props.imageType === 'picture' ? '5px' : '15px')};
  padding: 0 5px 0 5px;
`;

const CardIcon = styled.div`
  padding-top: ${(props) => (props.imageType === 'picture' ? '10px' : '40px')};
`;

const CardCheckIcon = styled.i`
  position: relative;
  left: 68px;
  top: ${(props) => (props.imageType === 'picture' ? '5px' : '18px')};
`;

const HiddenInput = styled.input`
  display: none;
`;

const Picture = styled.img`
  width: 140px;
`;

const getPictureUrl = (pictures, option) => {
  if (pictures === undefined) {
    return '';
  }
  const element = pictures.find((el) => el.name === option);
  return element === undefined ? '' : element.url;
};

const SingleChoice = ({ options, selected, register, inputName, handleChange, pictures, imageType = 'icon' }) => {
  return (
    <Row>
      {options.map((option, index) => (
        <Col md={4} sm={6} key={index}>
          <label htmlFor={option}>
            <Card checked={selected === option}>
              <CardIcon imageType={imageType}>
                {imageType === 'picture' ? (
                  <Picture height='100px' src={getPictureUrl(pictures, option)} />
                ) : (
                  <i className={pictures[option] + ' fa-4x'}></i>
                )}
              </CardIcon>
              <CardText imageType={imageType}>
                <p>{option}</p>
              </CardText>
              {selected === option && (
                <CardCheckIcon imageType={imageType} className='fas fa-check-square'></CardCheckIcon>
              )}
            </Card>
          </label>
          <HiddenInput
            name={inputName}
            id={option}
            type='radio'
            value={option}
            ref={register}
            onChange={handleChange}
            defaultChecked={selected === option}
          />
        </Col>
      ))}
    </Row>
  );
};

export { SingleChoice };
