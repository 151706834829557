import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';

const ColorPreview = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  position: relative;
  bottom: 33px;
  &:hover {
    cursor: pointer;
  }
`;

const AbsoluteDiv = styled.div`
  position: absolute;
`;

const ChromePickerWrapper = styled.div`
  position: relative
  bottom: 33px
  z-index: 999
`;

const GreyColorInput = styled.input``;
export default class InputColorPicker extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section>
        <GreyColorInput
          readOnly
          className='form-control grey-color-input color-picker-input'
          type='text'
          value={this.props.color}
          onClick={this.props.toggleColorPicker}
          name={this.props.name}
        />
        <ColorPreview color={this.props.color} onClick={this.props.toggleColorPicker} name={this.props.name} />
        {this.props.displayColorPicker && (
          <AbsoluteDiv onMouseLeave={() => this.props.closePicker(this.props.name)}>
            <ChromePickerWrapper>
              <ChromePicker
                color={this.props.color}
                onChange={this.props.handleColorPickerChange}
                onChangeComplete={this.props.handleColorPickerChangeComplete}
              />
            </ChromePickerWrapper>
          </AbsoluteDiv>
        )}
      </section>
    );
  }
}

InputColorPicker.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  toggleColorPicker: PropTypes.func,
  closePicker: PropTypes.func,
  displayColorPicker: PropTypes.bool,
  handleColorPickerChange: PropTypes.func,
  handleColorPickerChangeComplete: PropTypes.func,
};
