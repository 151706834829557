import "core-js/modules/es.set";
import "core-js/modules/es.map";

import React from 'react';
import ReactDOM from 'react-dom';
import SocialContentFormMessageTextArea from '../../components/social/content/form/message_text_area';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('socialContentFormMessageTextArea');

  if (node !== null) {
    const { isNew, message, inputName, inputId } = node.dataset;
    ReactDOM.render(
      <SocialContentFormMessageTextArea
        msg={message}
        isNew={isNew}
        inputName={inputName}
        inputId={inputId}
      />,
      node
    );
  }
});
