import { Row, Col } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const FontHelpBlock = styled.div`
  padding: 10px;
`;

const FontHelp = () => {
  return (
    <Row>
      <Col xs={3} className='text-right text-muted'></Col>
      <Col xs={9}>
        <FontHelpBlock className='grey-color-input'>
          <p>Les polices ci-dessous apportent la meilleure lisibilité dans les emails :</p>
          <pre>
            {['Arial', 'Georgia', 'Times New Roman', 'Trebuchet MS', 'Verdana'].map((font, index) => (
              <span key={index} style={{ fontFamily: font }}>
                {font}
                {index !== 4 && ', '}
              </span>
            ))}
          </pre>
          <p>Les polices suivantes sont également compatibles avec tous les clients mail :</p>
          <pre style={{ wordBreak: 'normal' }}>
            {[
              'Arial Black',
              'Book Antiqua',
              'Charcoal',
              'Comic Sans',
              'Courier New',
              'Geneva',
              'Helvetica',
              'Impact',
              'Lucida Console',
              'Lucida Sans Unicode',
              'Lucida Grande',
              'Monaco',
              'Palatino',
              'Palatino Linotype',
              'Tahoma',
              'Times',
            ].map((font, index) => (
              <span key={index} style={{ fontFamily: font }}>
                {font}
                {index !== 16 && ', '}
              </span>
            ))}
          </pre>
        </FontHelpBlock>
      </Col>
    </Row>
  );
};

export { FontHelp };
