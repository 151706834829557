import React from 'react';
import Step from '../../components/step';
import { Button } from 'react-bootstrap';
import { If } from '../../helpers';

export default function IntroductionStep({ nextStepFn, stepID, novice, isAdmin, progress, ...rest }) {
  const onNextClick = () => {
    nextStepFn(stepID);
  };

  return (
    <Step name='Introduction' stepID={stepID} progress={100} {...rest}>
      <p>Bonjour et bienvenu(e) chez Neocamino,</p>

      <div className='m-t-sm'>
        <p>
          Vous voilà arrivé(e) dans la toute première étape de votre diagnostic Neocamino : celle du questionnaire
          préliminaire.
        </p>

        <p>Notre objectif ? Mieux vous connaître avant d'échanger ensemble prochainement.</p>
        <p>
          Nous souhaitons avoir quelques informations concernant votre{' '}
          <If condition={novice} then="projet d'entreprise" else='entreprise' /> afin de préparer au mieux notre
          premier rendez-vous.
        </p>

        <p>
          Soyez concis et si vous hésitez, si vous ne comprenez pas ou si vous bloquez sur une question, pas de
          problème, nous verrons cela ensemble ! C'est justement l'objectif du 1er rendez-vous de diagnostic
          d'échanger sur tous ces éléments.
        </p>

        <p>
          Cela nous permettra de vous fournir ensuite, lors du deuxième rendez-vous, la stratégie personnalisée et le
          plan d'action le plus adapté à vos enjeux spécifiques.
        </p>
      </div>

      <div className='text-right m-t-sm'>
        <Button variant='primary' onClick={onNextClick}>
          Commencer !
        </Button>
      </div>
    </Step>
  );
}
