import { Sortable, AutoScroll, OnSpill } from 'sortablejs/modular/sortable.core.esm';

const InitSortableFieldValues  = function() {
  const el = document.querySelector('#landing_page_field_values');

  if (!el) {
    return;
  }

  Sortable.create(el, {
    draggable: '.nested-fields:not([style*="display: none"])',
    handle: '.draggable-field-value',
    animation: 150,
    onEnd: function (evt) {
      const list = this.el.querySelectorAll(this.options.draggable);

      list.forEach((sibling, index) => {
        let siblingPosition = sibling.querySelector("[name$='[position]']");

        if(!siblingPosition) {
          return;
        }

        siblingPosition.value = index;
      });
    }
  });
}

document.addEventListener('DOMContentLoaded', InitSortableFieldValues);
document.addEventListener('sortable:landing_page_field_values', InitSortableFieldValues);
