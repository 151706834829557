const baseFeaturesIcons = {
  "Sécurisé en https": "fas fa-shield-alt",
  "Responsive": "fas fa-mobile-alt",
  "Suivi google analytics": "fas fa-chart-line",
  "Techniquement optimal pour Google": "fab fa-google",
  "Performance optimisée": "fas fa-rocket",
  "Formulaires": "fas fa-bullseye",
  "Blog": "fas fa-bullhorn",
  "Popup contextuelles": "fas fa-mouse-pointer"
};

const additionalFeaturesIcons = {
  "Multilingue": "fas fa-globe-europe",
  "Moteur de recherche interne": "fas fa-search",
  "Simulateur de devis": "fas fa-calculator"
};

const websiteTypeIcons = {
  "Ecommerce": "fas fa-cart-arrow-down",
  "Site vitrine": "fas fa-laptop-code"
};

const projectTypeIcons = {
  "Création": "fas fa-magic",
  "Refonte": "fas fa-retweet"
};

const hostingIcons = {
  "Neocamino": "fas fa-user",
  "Moi même": "fas fa-user-edit",
  "Autre prestataire": "fas fa-user-shield"
};

export {
  baseFeaturesIcons,
  additionalFeaturesIcons,
  websiteTypeIcons,
  projectTypeIcons,
  hostingIcons
};
