import 'core-js/modules/es.set';
import 'core-js/modules/es.map';

import React from 'react';
import ReactDOM from 'react-dom';
import AnnotationService from '../services/annotation_service';
import PageTemplateAnnotator from '../components/page_template/annotator';

export default class GutterAnnotator {
  constructor(node, settings, formClass, cocoonParentSelector) {
    this.node = node;
    this.settings = settings;
    this.formClass = formClass;
    this.cocoonParentSelector = cocoonParentSelector;
  }

  elementHasRedactor = (input) => {
    return $(input)
      .parent()
      .hasClass('redactor_box');
  };

  redactorElement = (input) => {
    return $(input)
      .closest('.redactor_box')
      .find('.redactor_annotator-input');
  };

  buildTarget = (input) => {
    var inputId = input.id;
    var element;

    if (this.elementHasRedactor(input)) {
      inputId = 'redactor_' + inputId;
      element = this.redactorElement(input);
      element.prop('id', inputId);
    } else {
      element = $(`#${inputId}`);
    }

    const cocoonParentElement = element.closest(this.cocoonParentSelector);
    const collapseParentID = input.getAttribute('data-collapse-parent-id');

    const annotatedElementAttributes = {
      id: inputId,
      element: element,
      parentElement: element.parent(),
      title: input.getAttribute('data-annotator-input-title'),
      collapseParentElement: collapseParentID ? $(collapseParentID) : null,
      cocoonParentElement: cocoonParentElement,
    };

    return annotatedElementAttributes;
  };

  render() {
    const annotatorInputs = document.getElementsByClassName('annotator-input');
    const annotatorTargets = [];

    let annotationService = new AnnotationService(this.settings.urls, this.settings.author);

    for (var i = 0, len = annotatorInputs.length; i < len; i++) {
      const input = annotatorInputs[i];
      const annotatedTarget = this.buildTarget(input);
      annotatorTargets.push(annotatedTarget);
    }

    const formElement = $(this.formClass);

    annotationService.listAnnotations((data) => {
      ReactDOM.render(
        <PageTemplateAnnotator
          settings={this.settings}
          annotations={data}
          targets={annotatorTargets}
          formElement={formElement}
          annotationService={annotationService}
          buildTargetFn={this.buildTarget}
        />,
        this.node
      );
    });
  }
}
