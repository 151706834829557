import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListItem = styled.li`
  list-style-type: none;
`;

const List = styled.ul`
  padding: 0px;
`;

const Spacer = styled.div`
  margin-top: 10px;
`;

const Input = styled.input`
  display: block;
  height: 34px;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

const CheckIcon = styled.svg`
  margin-right: 5px;
`;

const CheckboxInput = ({ checked, onClick, option, text }) => {
  return (
    <div
      checked={!checked}
      onClick={onClick}
      option={option}
      className={'select-field-checkbox' + (checked ? ' checked' : '')}
    >
      <CheckIcon className='fas fa-check fa-fw icon-enabled' />
      <CheckIcon className='far fa-square fa-fw icon-disabled' />
      {text}
    </div>
  );
};

export default class ColumnPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
      options: this.props.contactFields,
      originalOptions: this.props.contactFields,
      search: '',
    };
  }

  toggleCheckbox = (e) => {
    const input = e.currentTarget;
    const optionName = input.textContent;
    const isChecked = input.checked;
    const option = this.getKeyByValue(this.props.translations, optionName);
    input.classList.toggle('checked');
    this.props.updateParentState(option, isChecked);
  };

  filterOptions = (search) => {
    const matchingTranslations = Object.values(this.props.translations).filter((val) =>
      val.toLowerCase().includes(search.toLowerCase())
    );
    const filteredItems = matchingTranslations.map((translation) => {
      return this.getKeyByValue(this.props.translations, translation);
    });
    this.setState({ options: filteredItems });
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  searchChange = (e) => {
    const searchOption = e.target.value;
    this.setState({ search: searchOption });
    this.filterOptions(searchOption);
  };

  isChecked = (option) => {
    return this.props.columns.includes(option);
  };

  translate = (option) => {
    return this.props.translations[option];
  };

  render() {
    return (
      <div>
        <h5>Choix des colonnes : </h5>
        <Input
          type='text'
          value={this.state.search}
          onChange={this.searchChange}
          placeholder='Rechercher des propriétés'
        />
        <List>
          <Spacer />
          {this.state.options.map((option, index) => (
            <ListItem key={index}>
              <CheckboxInput
                onClick={this.toggleCheckbox}
                checked={this.isChecked(option)}
                option={option}
                text={this.translate(option)}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

ColumnPicker.propTypes = {
  columns: PropTypes.array,
  updateParentState: PropTypes.func,
  contactFields: PropTypes.array,
  translations: PropTypes.object,
};

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  option: PropTypes.string,
};
