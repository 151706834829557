import React from 'react';
import { Collapse } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

export default function Step({ name, stepID, progress, isOpenFn, clickStepFn, children }) {
  const handleStepClick = () => {
    clickStepFn(stepID);
  };
  const isOpen = () => {
    return isOpenFn(stepID);
  };

  const onCollapseEntered = (collapseElement) => {
    resizeAllAutosizes(collapseElement);
    moveScrollPosition(collapseElement);
  };

  const resizeAllAutosizes = (collapseElement) => {
    const availableAutosizeFields = collapseElement.querySelectorAll('.autosize');

    if (availableAutosizeFields.length > 0) {
      autosize.update(availableAutosizeFields);
    }
  };

  const moveScrollPosition = (collapseElement) => {
    const ncStepContainer = collapseElement.parentElement.parentElement;
    const topScrollOffset = ncStepContainer.offsetTop - 155;

    $('html, body').animate({ scrollTop: topScrollOffset }, 1000);
  };

  const progressClass = () => {
    const progressClassBase = ' nc-step--progress--';

    if (progress === 100) {
      return progressClassBase + 'primary';
    }

    return '';
  };

  return (
    <Card className={`nc-step--container${progressClass()}`}>
      <div className='nc-step--infos' onClick={handleStepClick}>
        <div className='nc-step--infos-container'>
          <span className='nc-step--counter'>
            <span className='nc-step--counter-content' style={{ height: progress + '%' }} />
          </span>

          <div className='nc-step--title-line'>{name}</div>
        </div>
      </div>
      <div>
        <Collapse in={isOpen()} onEntered={onCollapseEntered}>
          <Card.Body>{children}</Card.Body>
        </Collapse>
      </div>
    </Card>
  );
}
