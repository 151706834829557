import { Row, Col } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import { Link } from './shared/styled_components';

const DomainInput = ({ register, inputName, defaultValue, handleChange, inputState }) => {
  return (
    <Row>
      <Col xs={11}>
        <input
          type='text'
          name={inputName}
          ref={register}
          className='form-control grey-color-input'
          autoComplete='off'
          defaultValue={defaultValue}
          onBlur={handleChange}
        />
      </Col>
      <Col xs={1}>
        <Link href={`http://${inputState}`} target='_blank'>
          <i className='fas fa-external-link-alt'></i>
        </Link>
      </Col>
    </Row>
  );
};

export { DomainInput };
