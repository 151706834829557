import React, { Component } from 'react';
import Annotator from '../../annotator';

export default class PageTemplateAnnotator extends Component {
  constructor(props) {
    super(props);

    this.annotatorInstancesRefs = {};

    this.state = {
      openedInstance: null,
      targets: this.props.targets,
    };
  }

  componentDidMount() {
    this.hookOnCocoonInsert();
  }

  hookOnCocoonInsert = () => {
    this.props.formElement.on('cocoon:after-insert-element', (e, insertedElement) => {
      const newInputs = insertedElement.getElementsByClassName('annotator-input');
      let newTargets = [];

      [...newInputs].forEach((input) => {
        newTargets.push(this.props.buildTargetFn(input));
      });

      e.stopPropagation();

      this.setState({ targets: [...this.state.targets, ...newTargets] });
    });
  };

  onAnnotationsVisibleFn = (instanceID) => {
    this.closePreviousInstance();

    this.setState({ openedInstance: instanceID });
  };

  closePreviousInstance = () => {
    const previousOpenedInstance = this.state.openedInstance;

    if (previousOpenedInstance !== null || previousOpenedInstance !== undefined) {
      this.closeInstanceAnnotations(previousOpenedInstance);
    }
  };

  onTargetFocusFn = () => {
    this.closePreviousInstance();
  };

  closeInstanceAnnotations = (instanceID) => {
    const openedInstanceRef = this.annotatorInstancesRefs[instanceID];

    if (openedInstanceRef && openedInstanceRef.annotationsVisible()) {
      openedInstanceRef.closeAnnotations();
    }
  };

  computeTargetID = (target) => {
    let targetID = target.id;

    if (target.cocoonParentElement.length > 0) {
      if (this.constructor.isRemovedFromDOM(target)) {
        return false;
      }

      targetID = targetID.replace(/[0-9]/g, '').replace(/_+/g, '_');
      targetID += '_' + target.cocoonParentElement.data('objectId');
    }

    return targetID;
  };

  static isRemovedFromDOM(target) {
    return target.cocoonParentElement.data('objectId') === undefined;
  }

  resolveAnnotations = (instance) => {
    instance.resolveAnnotations(false, false);
  };

  displayBubbles = () => {
    var pageTempateState = $('#pageTemplateState').data();
    if (pageTempateState === undefined) {
      return false;
    }
    return pageTempateState.editionDisabled ? true : false;
  };

  renderTargets = () => {
    let instances = [];
    const showBubbles = this.displayBubbles();

    this.state.targets.forEach((target) => {
      const targetID = this.computeTargetID(target);

      if (!targetID) {
        return;
      }

      let instance = (
        <Annotator
          key={targetID}
          targetInput={targetID}
          targetElement={target.element}
          parentElement={target.parentElement}
          collapseParentElement={target.collapseParentElement}
          cocoonParentElement={target.cocoonParentElement}
          title={target.title}
          annotations={this.props.annotations[targetID]}
          defaultAvatarUrl={this.props.settings.defaultAvatarUrl}
          annotationService={this.props.annotationService}
          onAnnotationsVisibleFn={this.onAnnotationsVisibleFn}
          onTargetFocusFn={this.onTargetFocusFn}
          bubbleVisible={showBubbles}
          onRef={(ref) => (this.annotatorInstancesRefs[targetID] = ref)}
        />
      );

      instances.push(instance);
    });

    return instances;
  };

  render() {
    return <div>{this.renderTargets()}</div>;
  }
}
