import * as yup from 'yup';
import React from 'react';
import PrediagHelpers from '../helpers';
import CommunicationActionsStep from '../components/steps/communication_actions_step';
import Step from './step';

export default class CommunicationActions extends Step {
  component = () => <CommunicationActionsStep />;

  sharedValues = () => {
    return {
      account_attributes: {
        id: this.diagnostic.account_id,
        website_url: this.account.website_url,
        blog_url: this.account.blog_url,
        facebook_page: this.account.facebook_page,
        twitter_profile: this.account.twitter_profile,
        linkedin_url_input: this.account.linkedin_url,
        google_my_business_page: this.account.google_my_business_page,
        pinterest_profile: this.account.pinterest_profile,
        instagram_profile: this.account.instagram_profile,
      },
      sales_channel:  this.diagnostic.sales_channel,
      sales_steps:    this.diagnostic.sales_steps,
      online_presence: this.diagnostic.online_presence,
      nb_visitors_per_month: this.diagnostic.nb_visitors_per_month,
      nb_leads_from_visitors_per_month: this.diagnostic.nb_leads_from_visitors_per_month,
      nb_contacts: this.diagnostic.nb_contacts,
    };
  };

  noviceValues = () => {
    return {
      personal_network_exists: PrediagHelpers.checkedValueForForm(this.diagnostic.personal_network_exists),
      personal_network_description: this.diagnostic.personal_network_description,

      crowd_funding_campaign_exists: PrediagHelpers.checkedValueForForm(
        this.diagnostic.crowd_funding_campaign_exists
      ),
      crowd_funding_campaign_description: this.diagnostic.crowd_funding_campaign_description,
    };
  };

  advancedValues = () => {
    return {
      contacts_source: this.diagnostic.contacts_source,
      nb_emailings_sent_last_12_months: this.diagnostic.nb_emailings_sent_last_12_months,
      average_open_rate: this.diagnostic.average_open_rate,
      average_click_rate: this.diagnostic.average_click_rate,
    };
  };

  adminValues = () => {
    return {
      website_tx_conversion:    this.diagnostic.website_tx_conversion,
      website_technologies:     this.diagnostic.website_technologies,
      website_wp_builder:       this.diagnostic.website_wp_builder,
      website_admin:            this.diagnostic.website_admin,
      website_erp_crm:          this.diagnostic.website_erp_crm,
      website_tiers_services:   this.diagnostic.website_tiers_services,
      website_payment_service: this.diagnostic.website_payment_service,
      website_domain_customer_journey: this.diagnostic.website_domain_customer_journey,
      online_ads:        this.diagnostic.online_ads,
      online_ads_budget: this.diagnostic.online_ads_budget
    }
  };

  validationSchema = () => {
    return yup.object().shape({
      nb_visitors_per_month: yup.string().max(255),

      nb_contacts: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .integer()
        .min(0)
        .nullable(),
      nb_emailings_sent_last_12_months: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .integer()
        .min(0)
        .nullable(),
      emailing_service: yup.string().max(255),

      average_open_rate: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .min(0)
        .max(100)
        .nullable(),
      average_click_rate: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .min(0)
        .max(100)
        .nullable(),

      account_attributes: yup.object().shape({
        website_url: yup
          .string()
          .url()
          .max(255),
        blog_url: yup
          .string()
          .url()
          .max(255),
        facebook_page: yup
          .string()
          .url()
          .max(255),
        twitter_profile: yup
          .string()
          .url()
          .max(255),
        linkedin_url_input: yup
          .string()
          .url()
          .max(255),
        google_my_business_page: yup
          .string()
          .url()
          .max(255),
        pinterest_profile: yup
          .string()
          .url()
          .max(255),
        instagram_profile: yup
          .string()
          .url()
          .max(255),
      }),
    });
  };
}
