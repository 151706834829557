import React from 'react';
import StepForm from '../../components/step_form';
import { NcInput } from '../../../../shared_components/neocamino/inputs';

export default function ServicesStep({ values, helpers, novice, isAdmin, ...rest }) {
  return (
    <StepForm name='Vos outils' values={values} isAdmin={isAdmin} {...rest}>
      <NcInput name='emailing_service' label="Utilisez-vous un outil d'emailing ? Si oui, lequel ?" type='text' />
      <NcInput
        name='crm_service'
        label='Où stockez-vous vos contacts et informations clients (Outil CRM, Excel, ...) ?'
        type='text'
      />
      <NcInput name='erp_service' label='Utilisez-vous un logiciel de facturation ? Si oui, lequel ?' type='text' />
      <NcInput name='seo_service' label='Utilisez-vous un outil SEO ? Si oui, lequel ?' type='text' />
      <NcInput
        name='prospect_service'
        label='Utilisez-vous un outil de prospection ? Si oui, lequel ?'
        type='text'
      />
      <NcInput
        name='publipost_service'
        label='Utilisez-vous un outil de publipostage pour vos réseaux sociaux  ? Si oui, lequel ?'
        type='text'
      />
      <NcInput
        name='marketing_service'
        label="Utilisez-vous d'autres outils marketing ? Si oui, lesquels ?"
        type='text'
      />
    </StepForm>
  );
}
