$(function() {
  $(document).on('change', 'input.url-link-trigger', function() {
    var input = $(this);
    var url = input.val();
    var cleanedUrl = cleanUrl(url);
    var urlLink = input.closest('.input-group').find('.url-link');
    urlLink.attr('href', cleanedUrl);
  });

  function cleanUrl(str) {
    if (str.startsWith('http')) {
      url = str;
    } else {
      url = 'https://' + str;
    }
    return url;
  }
});
