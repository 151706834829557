import React, { Component } from 'react'
import Icon from "../icon";
import Field from "../field";
import styled from "styled-components";

const StyledForm = styled.form`
  width: ${ props => props.formWidth }px;
`;

export default class Form extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledForm
        className="form-inline"
        id="app-search-box--form"
        action={ this.props.searchURL }
        acceptCharset="UTF-8"
        method="get"
        formWidth={ this.props.formWidth }>

        { this.props.children }

        <input name="user_id" type="hidden" value={ this.props.userID } />
        <input name="redirect_single_match" type="hidden" value={ true } />
      </StyledForm>
    )
  }
}
