import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FileUploadWrapper = styled.div`
  color: #9a9a9a;
  height: 65px;
`;

const LogoThumb = styled.img`
  position: relative;
  max-width: 75px;
  max-height: 50px;
  top: 5px;
`;

const CtaWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  bottom: 40px;
  left: 100px;
`;

const TrashIcon = styled.article`
  margin-left: 7px;
  cursor: pointer;
  &:hover {
    color: #777777;
  }
`;
const Link = styled.a`
  color: #9a9a9a;
  &:hover {
    color: #777777;
  }
`;
const DownloadIcon = styled.article`
  cursor: pointer;
`;
const Label = styled.label`
  position: relative;
  top: 5px;
  cursor: pointer;
  &:hover {
    color: #777777;
  }
`;
const FileUpload = ({ logoChange, name, picture, removeLogo }) => {
  return (
    <section>
      <FileUploadWrapper>
        <div>
          {!picture && (
            <Label htmlFor={name}>
              <p>+ ajouter</p>
            </Label>
          )}
          <input id={name} type='file' onChange={logoChange} name={name} style={{ display: 'none' }} />
        </div>
        {picture && (
          <section>
            <LogoThumb src={picture} />
            <CtaWrapper>
              <DownloadIcon data-tip='Télécharger'>
                <Link href={picture} download>
                  <i className='fas fa-download'></i>
                </Link>
              </DownloadIcon>
              <TrashIcon data-tip='Supprimer' onClick={(e) => removeLogo(e, name)}>
                <i className='fas fa-trash'></i>
              </TrashIcon>
            </CtaWrapper>
          </section>
        )}
      </FileUploadWrapper>
    </section>
  );
};

export { FileUpload };

FileUpload.propTypes = {
  logoChange: PropTypes.func,
  removeLogo: PropTypes.func,
  name: PropTypes.string,
  picture: PropTypes.string,
};
