import 'core-js/modules/es.set';
import 'core-js/modules/es.map';
import React from 'react';
import ReactDOM from 'react-dom';
import AssigneeDropdown from '../../components/assignee/dropdown';

document.addEventListener('DOMContentLoaded', () => {
  renderAssigneeDropdown(document);
});

// Can be called across the app to render the component on elements injected in the DOM
global.renderAssigneeDropdown = function(container) {
  const actionsCollection = container.querySelectorAll('.action-react-row .assigneeForm');

  if (actionsCollection.length === 0) {
    return;
  }

  const actions = Array.from(actionsCollection);

  actions.forEach(function(actionNode) {
    const {
      assigneeId,
      assigneeOptions,
      emptyAvatar,
      patchActionUrl,
      refreshActionUrl,
      singleMode,
      readonlyMode,
      currentAdmin,
    } = actionNode.dataset;
    ReactDOM.render(
      <AssigneeDropdown
        assigneeId={assigneeId}
        assigneeOptions={assigneeOptions}
        emptyAvatar={emptyAvatar}
        singleMode={singleMode}
        readonlyMode={readonlyMode}
        currentAdmin={currentAdmin}
        patchActionUrl={patchActionUrl}
        refreshActionUrl={refreshActionUrl}
      />,
      actionNode
    );
  });
};

export default AssigneeDropdown;
