import React, { Component } from 'react';
import styled from 'styled-components';
import Annotation from '../annotation';
import Form from '../form';

const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0 1px 6px 1px rgba(60, 64, 67, 0.15);
  padding: 1em;
  width: 250px;
  position: absolute;
  top: -15px;
  left: -35px;

  @media (min-width: 576px) {
    width: 150px;
  }
  @media (min-width: 992px) {
    width: 180px;
  }
  @media (min-width: 1050px) {
    width: 190px;
  }
  @media (min-width: 1100px) {
    width: 200px;
  }
  @media (min-width: 1150px) {
    width: 210px;
  }
  @media (min-width: 1180px) {
    width: 220px;
  }
  @media (min-width: 1200px) {
    width: 230px;
  }
  @media (min-width: 1260px) {
    width: 250px;
  }
  @media (min-width: 1400px) {
    width: 280px;
  }
  @media (min-width: 1500px) {
    width: 300px;
  }

  display: ${(props) => (props.visible ? 'block' : 'none')};

  .close {
    margin-top: -2px;
  }

  .btn {
    margin-left: 5px;
  }

  .alert {
    margin-top: 1em;
  }
`;

const TitleBar = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
`;

export default class Annotations extends Component {
  constructor(props) {
    super(props);
  }

  renderAnnotations = () => {
    if (this.props.annotations.length === 0) {
      return;
    }

    return this.props.annotations.map((item, key) => (
      <Annotation
        key={item.id}
        id={item.id}
        text={item.content}
        username={this.authorName(item.author)}
        photo={item.author.avatar.url || this.props.defaultAvatarUrl}
        isNote={item.internal}
        createdAt={item.created_at}
        canDelete={this.props.canDeleteFn(item)}
        deleteFn={this.props.deleteFn}
      />
    ));
  };

  authorName = (author) => {
    const fullName = [author.first_name, author.last_name].filter(Boolean).join(' ');

    return fullName || author.email;
  };

  renderError = () => {
    if (this.props.errorMessage) {
      return <div className='alert alert-danger'>{this.props.errorMessage}</div>;
    }
  };

  titleBar = () => {
    return (
      <TitleBar className='hstack'>
        <strong>{this.props.title || '\u00A0'}</strong>

        <div className='ms-auto'>
          <a className='btn btn-close' onClick={this.props.closeFn}></a>
        </div>
      </TitleBar>
    );
  };

  render() {
    let resolveBtn, actionsSeparator;

    if (this.props.annotations.length > 0) {
      resolveBtn = (
        <button className='btn btn-link' onClick={this.props.resolveFn}>
          Clore la discussion
        </button>
      );
    }

    if (this.props.annotations.length > 0) {
      actionsSeparator = <hr />;
    }

    return (
      <Container visible={this.props.visible}>
        {this.titleBar()}

        <ul className='media-list'>{this.renderAnnotations()}</ul>

        <div>
          <Form
            noteMode={this.props.noteMode}
            formSubmitFn={this.props.formSubmitFn}
            newAnnotationText={this.props.newAnnotationText}
            newAnnotationTextFn={this.props.newAnnotationTextFn}
            cancelFn={this.props.closeFn}
            setNoteModeFn={this.props.setNoteModeFn}
            canCreateNote={this.props.canCreateNote}
          />
        </div>

        {this.renderError()}

        <div className={'text-center'}>
          {actionsSeparator}

          {resolveBtn}
        </div>
      </Container>
    );
  }
}
