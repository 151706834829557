import { Col, Card } from 'react-bootstrap';
import { NcInput } from '../../../../shared_components/neocamino/inputs';
import React from 'react';

export default function BuyerPersona({ index, dbID, inputNameRoot, onRemoveFn, onBlurFn }) {
  let buyerName = `Acheteur ${index + 1}`;

  return (
    <Col md={6}>
      <Card className="mb-3">
        <Card.Body>
          <NcInput name={`${inputNameRoot}[${index}].id`} defaultValue={dbID} type='hidden' />

          <NcInput name={`${inputNameRoot}[${index}].name`} placeholder='Nom' onBlur={onBlurFn} maxLength='255' />

          <NcInput
            name={`${inputNameRoot}[${index}].description`}
            componentClass='textarea'
            placeholder='Description'
            onBlur={onBlurFn}
          />
        </Card.Body>

        <Card.Footer>
          {buyerName}

          <div className='pull-right'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                onRemoveFn(index);
              }}
            >
              Supprimer
            </a>
          </div>
        </Card.Footer>
      </Card>
    </Col>
  );
}
