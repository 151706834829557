import React from 'react';
import StepForm from '../../components/step_form';
import {
  NcFormInputWithAddon,
  NcInput,
  NcYesNoRadioWithCondition,
} from '../../../../shared_components/neocamino/inputs';
import { If } from '../../helpers';
import { Form } from 'react-bootstrap';

export default function CommunicationActionsStep({ values, novice, isAdmin, ...rest }) {
  const crowdFundingCampaignDescriptionInput = (
    <NcInput
      name='personal_network_description'
      type='text'
      componentClass='textarea'
      label='Précisez les caractéristiques de ce réseau'
      helpText='Type de personnes, nombre de contacts, etc.'
      className='admin'
    />
  );

  const personalNetworkDescriptionInput = (
    <NcInput
      name='crowd_funding_campaign_description'
      type='text'
      componentClass='textarea'
      label='Précisez votre choix et les résultats éventuels'
      className='admin'
    />
  );

  const yourContactsNovice = (
    <>
      <NcYesNoRadioWithCondition
        name='personal_network_exists'
        label="Disposez-vous d'un réseau auprès duquel communiquer le lancement de votre entreprise ?"
        thenComponent={crowdFundingCampaignDescriptionInput}
      />

      <NcYesNoRadioWithCondition
        name='crowd_funding_campaign_exists'
        label='Avez-vous mené une campagne de financement participatif ?'
        thenComponent={personalNetworkDescriptionInput}
      />
    </>
  );

  const yourContactsLaunched = (
    <>
      <h4>Votre base de contacts</h4>

      <NcInput
        name='contacts_source'
        label='Origine de la liste de contacts'
        type='text'
        componentClass='textarea'
        helpText='Salons, événements, formulaire de contact, etc.'
      />

      <h4>Animation de votre base de contacts</h4>

      <NcInput name='nb_emailings_sent_last_12_months' type='customNumber' label="Nombre d'emailings envoyés sur les 12 derniers mois" />

      <NcFormInputWithAddon name='average_open_rate' type='customNumber' label="Taux moyen d'ouverture" before={false}>
        %
      </NcFormInputWithAddon>

      <NcFormInputWithAddon name='average_click_rate' type='customNumber' label='Taux moyen de clic' before={false}>
        %
      </NcFormInputWithAddon>
    </>
  );

  const websiteInputs = (
    <>
      <NcInput
        name='website_tx_conversion'
        label='Mesurez-vous votre taux de conversion ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_technologies'
        label='Sur quelle technologie est votre site (Wordpress, Wix, Jimdo, autre) ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_wp_builder'
        label='Site WP, avez-vous un visual builder ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_admin'
        label='Avez-vous la main sur le site ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_erp_crm'
        label='Est-il connecté à un ERP ? à un CRM ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_domain_customer_journey'
        label='Tout le parcours client se fait-il sur un seul et même domaine ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_tiers_services'
        label='Si non, quelles sont les plateforme associées à votre site ? (Calendly, Treatwell, etc.)'
        type='text'
        componentClass='textarea'
        className='admin'
      />
      <NcInput
        name='website_payment_service'
        label='Si e-commerce : quelle est la plateforme de paiement ?'
        type='text'
        componentClass='textarea'
        className='admin'
      />
    </>
  );

  const adsInputs = (
    <>
      <NcInput
        name='online_ads'
        label='Avez-vous déjà fait de la publicité en ligne ?'
        type='text'
        componentClass='textarea'
      />
      <NcInput
        name='online_ads_budget'
        label='Si oui, pour quel budget mensuel moyen ?'
        type='text'
        componentClass='textarea'
      />
    </>
  );

  return (
    <StepForm name='Vos actions de communication' values={values} isAdmin={isAdmin} {...rest}>

      <NcInput
        name='sales_channel'
        label='Quels sont vos canaux de vente (en ligne, hors ligne) ?'
        type='text'
        componentClass='textarea'
      />

      <NcInput
        name='sales_steps'
        label='Quelles sont les étapes de vente ?'
        type='text'
        componentClass='textarea'
      />

      <h3 className='m-t-0'>Votre présence en ligne</h3>

      <Form.Text>Vous êtes déjà sur le web ? Merci de renseigner les champs qui suivent.</Form.Text>

      <NcInput name='account_attributes[id]' type='hidden' />

      <NcInput name='account_attributes[website_url]' label='Site' type='url' />
      <NcInput name='account_attributes[blog_url]' label='Blog' type='url' />
      <NcInput name='account_attributes[facebook_page]' label='Facebook' type='url' />
      <NcInput name='account_attributes[twitter_profile]' label='Twitter' type='url' />
      <NcInput name='account_attributes[linkedin_url_input]' label='LinkedIn' type='url' />
      <NcInput name='account_attributes[google_my_business_page]' label='Google My Business' type='url' />
      <NcInput name='account_attributes[pinterest_profile]' label='Pinterest' type='url' />
      <NcInput name='account_attributes[instagram_profile]' label='Instagram' type='url' />

      <NcInput
        name='online_presence'
        label='Autres'
        type='text'
        componentClass='textarea'
        helpText="Deuxième site / snapchat / annuaires / site d'avis, etc."
      />

      <If condition={isAdmin} then={adsInputs} />

      <NcInput
        name='nb_visitors_per_month'
        label='Combien avez-vous de visiteurs par mois sur votre site ?'
        type='text'
      />
      <NcInput
        name='nb_leads_from_visitors_per_month'
        label='Combien cela génère-t-il de ventes ou de contacts ?'
        type='text'
        componentClass='textarea'
      />

      <If condition={isAdmin} then={websiteInputs} />

      <h3>Vos contacts</h3>

      <NcInput name='nb_contacts' type='customNumber' label='Nombre de contacts dans la base' />

      <If condition={novice} then={yourContactsNovice} else={yourContactsLaunched} />
    </StepForm>
  );
}
