import IntroductionStep from '../components/steps/introduction_step';
import React from 'react';
import Step from './step';

export default class Introduction extends Step {
  component = () => <IntroductionStep />;
  values = () => {
    return {};
  };
}
