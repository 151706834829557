import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 15px;
`;

const CommentBox = styled.div`
  background: ${(props) => (props.noteMode ? '#fef6d7' : 'transparent')};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 1em;

  > textarea {
    background-color: transparent;
    border: none;
    float: none !important;
    box-shadow: none;
    min-height: 40px;
    resize: vertical;
    width: 100%;

    &:focus {
      box-shadow: none;
    }
  }
`;

const NoteButtons = styled.div`
  border-bottom: ${(props) => (props.noteMode ? 'solid 1px rgba(0,0,0,.08)' : 'solid 1px #e6dec2')};

  button:first-child {
    text-decoration: ${(props) => (props.noteMode ? 'none' : 'underline')};
  }

  button:last-child {
    text-decoration: ${(props) => (props.noteMode ? 'underline' : 'none')};
  }
`;

export default class Form extends Component {
  static defaultProps = {
    canCreateNote: false,
  };

  constructor(props) {
    super(props);
  }

  changeFn = (event) => {
    this.props.newAnnotationTextFn(event.target.value);
  };

  noteButtons = () => {
    if (this.props.canCreateNote) {
      return (
        <NoteButtons noteMode={this.props.noteMode}>
          <button type='button' className='btn btn-link btn-sm' onClick={() => this.props.setNoteModeFn(false)}>
            Commentaire
          </button>
          <button type='button' className='btn btn-link btn-sm' onClick={() => this.props.setNoteModeFn(true)}>
            Note
          </button>
        </NoteButtons>
      );
    }
  };

  textareaPlaceholder = () => {
    const suffixText = this.props.canCreateNote ? ' au client' : '';
    return this.props.noteMode ? 'Votre note interne' : `Votre commentaire${suffixText}`;
  };

  render() {
    return (
      <Container>
        <CommentBox noteMode={this.props.noteMode}>
          {this.noteButtons()}

          <textarea
            className='form-control no-autosave'
            cols='30'
            rows='2'
            onChange={this.changeFn}
            placeholder={this.textareaPlaceholder()}
            value={this.props.newAnnotationText}
          />
        </CommentBox>

        <div className='hstack'>
          <button type='button' className='btn btn-default ms-auto' onClick={this.props.cancelFn}>
            Annuler
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={this.props.newAnnotationText.length === 0}
            onClick={this.props.formSubmitFn}
          >
            Valider
          </button>
        </div>
      </Container>
    );
  }
}
