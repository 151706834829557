import React, { Component } from 'react';
import PrediagConfig from './config';
import { Button } from 'react-bootstrap';
import PrediagHelpers, { If } from './helpers';
import GlobalProgressBar from './components/progress_bar';

export default class PrediagForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: this.props.isAdmin,
      diagnostic: this.props.diagnostic,
      novice: this.isNoviceProp(),

      currentStepID: this.props.currentStepID,
      savingStepID: null,
      autoSaving: false,

      progresses: {},

      errorMessage: null,
    };
  }

  static defaultProps = {
    currentStepID: 0,
    showRoleToggle: false,
  };

  componentDidMount() {
    this.buildProgress();
  }

  isNoviceProp = () => {
    return this.props.diagnostic.entrepreneur_profile === 'novice';
  };

  onSubmit = (stepID, data) => {
    if (stepID) {
      this.setSavingStep(stepID);
    }

    return this.saveForm(stepID, data).then(
      () => {
        this.setSavingStep(null);
        this.nextStep(stepID);
      },
      () => {
        this.setSavingStep(null);
      }
    );
  };

  onAutoSave = (stepID, data) => {
    this.setAutoSaving(true);

    return this.saveForm(stepID, data).then(
      () => {
        this.setAutoSaving(false);
      },
      () => {
        this.setAutoSaving(false);
      }
    );
  };

  saveForm = (stepID, data) => {
    return this.props.service.updateDiagnostic(data).then(
      (response) => {
        this.updateStateDiag(stepID, response.data);
      },
      (res) => {
        console.error(res);
        this.logGlobalError(PrediagConfig.ERRORS['HTTP_GENERIC_ERROR']);
      }
    );
  };

  updateStateDiag = (stepID, newDiag) => {
    this.setState({ diagnostic: newDiag }, () => {
      this.setStepProgress(stepID, PrediagConfig.stepValues(newDiag, stepID, this.state.novice, this.state.isAdmin));
    });
  };

  setSavingStep = (stepID) => {
    this.setState({ savingStepID: stepID });
  };

  setAutoSaving = (active) => {
    this.setState({ autoSaving: active });
  };

  clickStep = (stepID) => {
    let newStepID;

    if (this.isOpen(stepID)) {
      newStepID = null;
    } else {
      newStepID = stepID;
    }

    this.setStep(newStepID);
  };

  setStep = (stepID) => {
    return this.setState({ currentStepID: stepID });
  };

  nextStep = (stepID) => {
    this.setStep(stepID + 1);
  };

  finishSteps = () => {
    window.location.href = this.props.finishURL;
  };

  isOpen = (stepID) => {
    return this.state.currentStepID === stepID;
  };

  logGlobalError = (message) => {
    this.setState({ errorMessage: message });
  };

  clearError = () => {
    this.logGlobalError(null);
  };

  // This reload data depending on the toggled profile.
  // @note Data wrapped in If component will not be reloaded.
  toggleRoleButton = () => {
    const noviceMessage = ' lancement';
    const advancedMessage = ' croissance / stratégie';

    const stateCallback = (previousState) => ({ novice: !previousState.novice });

    return (
      <div className='toggle-role-btn'>
        <Button
          onClick={() => {
            this.setState(stateCallback, this.buildProgress);
          }}
        >
          Basculer en mode
          <If condition={this.state.novice} then={advancedMessage} else={noviceMessage} />
        </Button>
      </div>
    );
  };

  buildProgress = () => {
    PrediagConfig.getSteps(this.state.diagnostic, this.state.novice).forEach((step, index) => {
      this.setStepProgress(index, step.values());
    });
  };

  setStepProgress = (stepID, stepValues) => {
    if (Object.values(stepValues).length === 0) {
      return;
    }

    let currentProgresses = this.state.progresses;
    currentProgresses[stepID] = PrediagHelpers.valuesCompletionPercent(stepValues);

    this.setState({ progresses: currentProgresses });
  };

  globalProgress = () => {
    const progresses = Object.values(this.state.progresses);
    const sum = progresses.reduce((prev, current) => {
      return prev + current;
    }, 0);

    return Math.trunc(sum / progresses.length);
  };

  render() {
    return (
      <>
        <GlobalProgressBar
          progress={this.globalProgress()}
          isAutoSaving={this.state.autoSaving}
          errorMessage={this.state.errorMessage}
          clearErrorFn={this.clearError}
        />

        {PrediagConfig.getSteps(this.state.diagnostic, this.state.novice, this.state.isAdmin).map((step, index) => {
          const component = step.component();

          return React.createElement(component.type, {
            ...{
              ...component.props,
              stepID: index,
              key: index,
              values: step.values(),
              validationSchema: step.validationSchema(),
              progress: this.state.progresses[index],
              helpers: this.state.diagnostic.helpers,
              novice: this.state.novice,
              isAdmin: this.state.isAdmin,
              saving: index === this.state.savingStepID,
              onSubmitFn: this.onSubmit,
              onAutoSaveFn: this.onAutoSave,
              isOpenFn: this.isOpen,
              clickStepFn: this.clickStep,
              nextStepFn: this.nextStep,
              finishFn: this.finishSteps,
            },
          });
        })}

        {this.props.showRoleToggle && this.toggleRoleButton()}
      </>
    );
  }
}
