import "core-js/modules/es.set";
import "core-js/modules/es.map";

import React from "react";
import ReactDOM from "react-dom";
import PrediagPage from "../../components/prediag_page";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById('prediagPage');

  if (node !== null) {
    const { isadmin, diagnostic, showRoleToggle, urls } = node.dataset;
    ReactDOM.render(<PrediagPage isAdmin={isadmin !== 'false'} diagnostic={ JSON.parse(diagnostic) } showRoleToggle={ showRoleToggle === 'true' }
                                 urls={ JSON.parse(urls) } />, node);
  }
});
