import React, { Component } from 'react'
import styled from 'styled-components'
import Form from "./form";
import Icon from "./icon";
import Field from "./field";
import { lighten } from 'polished'

const AppSearchBoxContainer = styled.div`
  background-color: ${props => props.openSearch ? lighten(0.1, props.bgColor) : null};

  width: ${props => props.openSearch ? props.searchWidth : 50}px;
  overflow: hidden;

  transition: width 300ms ease-in-out;
`;

export default class AppSearchBox extends Component {
  static defaultProps = {
    searchWidth: 300,
    iconWidth: 50,
    fieldWidth: 250,
  };

  constructor(props) {
    super(props);

    this.state = {
      openSearch: this.props.data.openSearch || false,
      searchText: this.props.data.searchText || '',
    }
  }

  toggleSearch = () => {
    let fieldInput = this.refs.field.refs.fieldInput;
    const shouldOpenSearch = !this.state.openSearch;

    this.setState({ openSearch: shouldOpenSearch, searchText: '' });

    if (shouldOpenSearch) {
      fieldInput.focus();
    } else {
      fieldInput.blur();
    }
  };

  searchTextInput = (text) => {
    this.setState({ searchText: text })
  };

  render() {
    return (
      <AppSearchBoxContainer
        openSearch={ this.state.openSearch }
        searchWidth={ this.props.searchWidth }
        bgColor={ this.props.data.bgColor } >
        <Form
          searchURL={ this.props.data.searchURL }
          userID={ this.props.data.userID }
          formWidth={ this.props.searchWidth }>
          <Icon
            onClick={ this.toggleSearch }
            iconTitle={ this.props.data.fieldPlaceholder }
            iconWidth={ this.props.iconWidth }
            bgColor={ this.props.data.bgColor }
          />
          <Field
            ref="field"
            onChange={ this.searchTextInput }
            searchText={ this.state.searchText }
            fieldName={ this.props.data.fieldName }
            fieldPlaceholder={ this.props.data.fieldPlaceholder }
            fieldWidth={ this.props.fieldWidth }
          />
        </Form>
      </AppSearchBoxContainer>
    )
  }
}
