import React from 'react';
import StepForm from '../../components/step_form';
import {
  NcFormInputWithAddon,
  NcInput,
  NcYesNoRadioWithCondition,
} from '../../../../shared_components/neocamino/inputs';
import { If } from '../../helpers';

export default function ResultsAndObjectivesStep({ values, novice, isAdmin, ...rest }) {
  const profiledLabel = (labelText, variationIndex = 0) => {
    const noviceVariations = ['prévisionnel', 'prévisionnelle'];
    const advancedVariations = ['actuel', 'actuelle'];

    const suffix = novice ? noviceVariations[variationIndex] : advancedVariations[variationIndex];

    return `${labelText} ${suffix}`;
  };

  const serviceSellsHelpTextNo = 'Êtes-vous prêt(e) ? Avez-vous les compétences commerciales ?';

  const serviceSellsLabelTextYes = 'Précisez le nombre de ventes, le montant et le type de client :';
  const serviceSellsLabelTextNo = 'Qu’est-ce qui vous empêche de vendre aujourd’hui ?';

  const oneYearQualitativeGoalHelpText =
    'Exemples : réussir mon lancement, diversifier ma clientèle,' +
    ' arrêter la publicité, développer mes compétences, mettre ma communication au carré, etc.';
  const oneYearQuantitativeGoalHelpText =
    'Exemples : atteindre 100k€ de CA, augmenter de 20% mon nombre de leads,' +
    ' améliorer mon taux de transformation, remonter en 1ère position sur Google, etc.';

  const buyingFrequencyHelpText = (novice) =>
    `Combien de fois en moyenne un client ${novice ? 'pourrait acheter' : 'achète'}` +
    ' votre produit ou service chaque année ?';

  const buyingDistributionLabel = profiledLabel('La répartition mensuelle', 1) + ' du CA par produit/service';
  const buyingDistributionHelpText = 'Estimez la part de CA de chaque produit/service chaque mois.';

  const conversionHelpText =
    'Expliquez comment vous vendez, comment vos clients vous trouvent et les' +
    ' taux de concrétisation pertinent pour vous. Donnez-nous le plus de chiffres possible.';

  const serviceSellsInput = (yesValue) => {
    return (
      <NcInput
        name='service_sells_description'
        type='text'
        componentClass='textarea'
        label={yesValue ? serviceSellsLabelTextYes : serviceSellsLabelTextNo}
        helpText={yesValue ? null : serviceSellsHelpTextNo}
      />
    );
  };

  const serviceSellsInputs = (
    <NcYesNoRadioWithCondition
      name='service_sells_exist'
      label="Avez-vous déjà vendu l'un de vos produits ou service ?"
      thenComponent={serviceSellsInput(true)}
      elseComponent={serviceSellsInput(false)}
    />
  );

  const twoYearGoalInputs = (
    <NcInput
      name='two_year_goal'
      label="Dans un monde idéal, décrivez-nous votre situation dans 2 ans ? Qu'est-ce qui serait différent ?"
      type='text'
      componentClass='textarea'
      className='admin'
    />
  );

  const revenuAndGrowthInputs = (
    <>
      <NcFormInputWithAddon name='yearly_revenues' className='admin' type='customNumber' label={profiledLabel('Le CA annuel')} before={false}>
        €
      </NcFormInputWithAddon>
      <NcInput
        name='growth_one_year'
        label="Croissance année -1"
        type='text'
        componentClass='textarea'
        className='admin'
      />
    </>
  );

  const caAdminInputs = (
    <>
      <NcInput name='nb_customers' className='admin' type='customNumber' label="Nombre de clients"/>
      <NcInput
        name='tx_average_transform'
        type='text'
        componentClass='textarea'
        label='Taux de transformation moyen actuel'
        className='admin'
      />
      <NcInput
        name='periodicity'
        type='text'
        componentClass='textarea'
        label='Récurrence / saisonnalité'
        className='admin'
      />
      <NcInput
        name='star_product'
        type='text'
        componentClass='textarea'
        label='Produit(s) / offre phare(s)'
        className='admin'
      />
    </>
  );

  const conversionInput = (
    <NcInput
      name='conversion'
      type='text'
      componentClass='textarea'
      htmlFor='conversion_field'
      label='Comment générez-vous votre CA ?'
      helpText={conversionHelpText}
    />
  );

  return (
    <StepForm name='Vos objectifs et résultats' values={values} isAdmin={isAdmin} {...rest}>
      <NcInput
        name='one_year_qualitative_goal'
        label="L'objectif à 1 an (qualitatif)"
        type='text'
        componentClass='textarea'
        helpText={oneYearQualitativeGoalHelpText}
      />
      <NcInput
        name='one_year_quantitative_goal'
        label="L'objectif à 1 an (quantitatif)"
        type='text'
        componentClass='textarea'
        helpText={oneYearQuantitativeGoalHelpText}
      />

      <If condition={novice} then={serviceSellsInputs} />
      <If condition={isAdmin} then={twoYearGoalInputs} />

      <NcFormInputWithAddon name='monthly_revenues' type="customNumber" label={profiledLabel('Le CA mensuel moyen')} before={false}>
        €
      </NcFormInputWithAddon>

      <If condition={isAdmin} then={revenuAndGrowthInputs} />

      <NcFormInputWithAddon name='average_basket' type="customNumber" label={profiledLabel('Le panier moyen')} before={false}>
        €
      </NcFormInputWithAddon>

      <NcInput
        name='buying_frequency'
        label={profiledLabel("La fréquence d'achat", 1)}
        type='text'
        helpText={buyingFrequencyHelpText(novice)}
      />

      <If condition={isAdmin} then={caAdminInputs} />

      <NcInput
        name='buying_distribution'
        label={buyingDistributionLabel}
        type='text'
        componentClass='textarea'
        helpText={novice && buyingDistributionHelpText}
      />

      <If condition={!novice} then={conversionInput} />

      <NcInput
        name='source_distribution'
        type='text'
        componentClass='textarea'
        label='Comment sont réparties vos sources d’acquisition de clients ?'
      />
    </StepForm>
  );
}
