$(function() {
  $(document).on('change', "input.other_button_input[value='Autre']", function() {
    var input = $(this);
    var value = input.val();
    var other_field = input.closest('.check_boxes_other_form').find('.other_text_input');
    if (input.is(':checked')) {
      other_field.removeClass('hidden');
    } else {
      other_field.addClass('hidden');
    }
  });
});
