import React, { useState, useRef, useEffect } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import styled from 'styled-components';

const WordCounterWrapper = styled.div`
  position: absolute;
  right: 21px;
`;

const WordCounter = styled.span`
  float: right;
  position: relative;
  bottom: 15px;
`;

const IconWrapper = styled.span`
  position: absolute;
  right: 21px;
  top: ${(props) => (props.isNew ? '91px' : '36px')}
  width: 40px
  background-color: #e7e7e7;
  padding: 10px;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  overflow: hidden visible;
  overflow-wrap: break-word;
  padding-right: 30px;
`;

const SocialContentFormMessageTextArea = ({ msg, isNew, inputName, inputId }) => {
  const [displayWordCounter, setDisplayWordCounter] = useState(false);
  const [displayPicker, setDisplayPicker] = useState(false);
  const [message, setMessage] = useState(msg);
  const [messageLength, setMessageLength] = useState(0);
  const [messageMaxLength, setMessageMaxLength] = useState(65535);

  const countMessageLength = (msg) => {
    if (hasTwitter()) {
      return replaceUrlLength(msg);
    } else {
      return msg.length;
    }
  };

  const replaceUrlLength = (msg) => {
    // https://regex101.com/r/ugpZPT/1
    const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g;
    const urlMatches = msg.match(regex);
    var msgLength = msg.length;

    if (urlMatches) {
      urlMatches.forEach((match) => {
        const urlLength = match.length;

        msgLength = msgLength - urlLength + 23;
      });
    }

    if (inputUrlFilled()) {
      msgLength = msgLength + 23;
    }

    return msgLength;
  };

  const inputUrlFilled = () => {
    const inputUrl = $('#social_content_url')[0] || $('#social_post_url')[0];
    return inputUrl && inputUrl.value;
  };

  const inputChange = (e) => {
    setMessage(e.target.value);
    const messageCount = countMessageLength(e.target.value);
    setMessageLength(messageCount);
  };

  useEffect(() => {
    hookAutosize();
  }, []);

  const addEmoji = (e) => {
    let msg = message;
    msg += e.native;
    setMessage(msg);
  };

  const togglePicker = () => {
    setDisplayPicker(!displayPicker);
  };

  const hasTwitter = () => {
    return $('#social_content_providers_twitter').prop('checked') || $('.social_post').hasClass('twitter');
  };

  const hasLinkedin = () => {
    return (
      $('#social_content_providers_linkedin').prop('checked') ||
      $('#social_content_providers_linkedin_page').prop('checked') ||
      $('.social_post').hasClass('linkedin') ||
      $('.social_post').hasClass('linkedin_page')
    );
  };

  const textAreaFocus = () => {
    if (hasTwitter()) {
      setDisplayWordCounter(true);
      setMessageMaxLength(280);
      setMessage(message.slice(0, 280));
      setMessageLength(countMessageLength(message));
    } else if (hasLinkedin()) {
      setDisplayWordCounter(true);
      setMessageMaxLength(3000);
      setMessage(message.slice(0, 3000));
      setMessageLength(countMessageLength(message));
    } else {
      setDisplayWordCounter(false);
      setMessageMaxLength(65335);
    }
  };

  return (
    <section>
      <TextArea
        className='form-control text required autosize'
        rows='3'
        required='required'
        aria-required='true'
        placeholder='Message'
        name={inputName}
        id={inputId}
        onBlur={() => setDisplayWordCounter(false)}
        onFocus={textAreaFocus}
        value={message}
        onChange={inputChange}
      ></TextArea>
      {displayPicker && (
        <div>
          <Picker onSelect={addEmoji} />
        </div>
      )}
      {displayWordCounter && (
        <WordCounterWrapper>
          <WordCounter
            className={messageLength > messageMaxLength ? 'badge text-bg-danger' : 'badge text-bg-success'}
          >{`${messageLength} / ${messageMaxLength}`}</WordCounter>
        </WordCounterWrapper>
      )}
      <IconWrapper className='text-center' onClick={togglePicker} isNew={isNew}>
        <span className='icon-smile-picker'>
          <i className='far fa-smile'></i>
        </span>
      </IconWrapper>
    </section>
  );
};

export default SocialContentFormMessageTextArea;
