import React from 'react';
import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import { Link, Trash, Ctas, AddLink } from './shared/styled_components';

const InputWrapper = styled.div`
  margin-top: ${(props) => (props.withLink === true ? '0px' : '5px')};
`;

const SingleDynamicInput = ({ register, handleChange, control, inputName, currentState, withLink = false }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputName,
  });

  const removeDomain = (index) => {
    remove(index);
    setTimeout(() => {
      handleChange();
    }, 100);
  };

  const addDomain = () => {
    append({ name: '' });
    setTimeout(() => {
      handleChange();
    }, 100);
  };

  const getUrl = (currentState, field) => {
    if (currentState === undefined) {
      return '';
    }
    const element = currentState.find((el) => el.id === field.id);
    return element === undefined ? '' : element.name;
  };

  return (
    <div>
      {fields.map((field, index) => (
        <InputWrapper key={field.id} className='row' withLink={withLink}>
          <div className='col-xs-11'>
            <input
              type='text'
              name={`${inputName}[${index}].name`}
              ref={register({})}
              defaultValue={field.name}
              className='form-control grey-color-input'
              autoComplete='off'
              onBlur={handleChange}
            />
            <input
              name={`${inputName}[${index}].id`}
              defaultValue={field.id}
              ref={register({})}
              className='hidden'
            />
          </div>
          <Ctas className='col-xs-1'>
            {withLink && (
              <Link href={`http://${getUrl(currentState, field)}`} target='_blank'>
                <i className='fas fa-external-link-alt'></i>
              </Link>
            )}
            <Trash onClick={() => removeDomain(index)} withLink={withLink}>
              <i className='fas fa-trash'></i>
            </Trash>
          </Ctas>
        </InputWrapper>
      ))}
      <AddLink onClick={() => addDomain()}>+ Ajouter</AddLink>
    </div>
  );
};

export { SingleDynamicInput };
