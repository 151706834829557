import React from 'react';
import { NcButton, NcInput } from '../../../../shared_components/neocamino/inputs';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Form, Row } from 'react-bootstrap';
import BuyerPersona from './buyer_persona';

export default function BuyerPersonasFields({ onRemoveFn, onBlurFn, stateValues }) {
  const { control, getValues } = useFormContext();
  const inputNameRoot = 'account_attributes[buyer_personas_attributes]';
  const { fields, append, remove } = useFieldArray({ control, name: inputNameRoot, keyName: 'cID' });

  const onRemove = (index) => {
    onRemoveFn(index).then(() => {
      remove(index);
    });
  };

  const onAppend = () => {
    append({ name });
  };

  const onBlur = (e) => {
    onBlurFn(e, getValues({ nest: true }));
  };

  return (
    <>
      <h4>Les profils de vos acheteurs</h4>

      <Form.Text>
        <p>
          Qui est votre profil cible ? Il est important de vous poser de nombreuses questions pour l’identifier et le
          comprendre.
        </p>

        <p>
          Homme ou femme ? Age ? Citadin ? Parent ? Profession ? Revenus ? Etudes ? Centres d’intérêt ? Est-ce qu’il
          s’informe et consomme en ligne ? Utilise-t-il les réseaux sociaux ? Lesquels ? Est-il à l'aise sur internet
          ?
        </p>
      </Form.Text>

      <NcInput name='account_attributes[id]' type='hidden' />

      <Row>
        {fields.map((item, index) => {
          return (
            <BuyerPersona
              key={item.cID}
              dbID={stateValues[index] && stateValues[index]['id']}
              index={index}
              inputNameRoot={inputNameRoot}
              onRemoveFn={onRemove}
              onBlurFn={onBlur}
            />
          );
        })}
      </Row>

      <section>
        <NcButton text='Ajouter un profil' type='button' onClick={onAppend} />
      </section>
    </>
  );
}
