import React, { Component } from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';

const Card = styled.li`
  padding: 10px;
  border: 1px solid #efefef;
  z-index: 99999;
  list-style-type: none;
  background: white;
  user-select: none;
  &:hover {
    cursor: move;
  }
`;

const List = styled.ul`
  padding: 0px;
  background: #f3f3f3;
`;

const Icon = styled.i`
  margin-right: 10px;
`;

const CardValue = styled.span`
  margin-left: 7px;
`;

const SortableItem = SortableElement(({ value, translations }) => {
  const option = translations[value];
  return (
    <Card>
      <Icon className='fas fa-ellipsis-v' />
      <CardValue>{option}</CardValue>
    </Card>
  );
});

const SortableList = SortableContainer(({ items, translations }) => {
  return (
    <List>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} translations={translations} />
      ))}
    </List>
  );
});

export default class ColumnDnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
    };
  }

  componentWillReceiveProps(newState) {
    const currentColumns = this.state.columns;
    const newColumns = newState.columns;
    if (currentColumns !== newColumns) {
      this.setState({ columns: newColumns });
    }
  }

  postColumnsSorting = () => {
    this.props.onPatchColumns(this.state.columns);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ columns }) => ({
        columns: arrayMove(columns, oldIndex, newIndex),
      }),
      () => this.postColumnsSorting()
    );
  };

  render() {
    return (
      <section>
        <h5>Ordre des colonnes : </h5>
        <SortableList
          items={this.state.columns}
          onSortEnd={this.onSortEnd}
          distance={10}
          translations={this.props.translations}
        />
      </section>
    );
  }
}

ColumnDnd.propTypes = {
  columns: PropTypes.array,
  onPatchColumns: PropTypes.func,
  translations: PropTypes.object,
};
