import * as yup from 'yup';
import React from 'react';
import CompanyStep from '../components/steps/company_step';
import Step from './step';

export default class Company extends Step {
  component = () => <CompanyStep />;

  sharedValues = () => {
    return {
      account_attributes: {
        id: this.diagnostic.account_id,
        company_name: this.account.company_name,
        business_ages_range_id: this.account.business_ages_range_id,
      },
      marketing_canvas_attributes: {
        id: this.diagnostic.marketing_canvas.id,
        mission: this.diagnostic.marketing_canvas.mission,
        vision: this.diagnostic.marketing_canvas.vision,
      },
      project_supervisors: this.diagnostic.project_supervisors,
      project_decision: this.diagnostic.project_decision,
      project_op_supervisor: this.diagnostic.project_op_supervisor,
      project_op_contributor: this.diagnostic.project_op_contributor,
      roadmap_supervisors: this.diagnostic.roadmap_supervisors
    };
  };

  noviceValues = () => {
    return {
      professional_experience: this.diagnostic.professional_experience,
      project_genesis: this.diagnostic.project_genesis,
    };
  };

  advancedValues = () => {
    return {
      company_position: this.diagnostic.company_position,
      account_attributes: {
        nb_employees_range_id: this.account.nb_employees_range_id,
      },
    };
  };

  validationSchema = () => {
    return yup.object().shape({
      company_position: yup.string().max(255),
      professional_experience: yup.string().max(255),

      account_attributes: yup.object().shape({
        company_name: yup
          .string()
          .required()
          .max(255),
      }),
    });
  };
}
