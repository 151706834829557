import { Datepicker } from 'vanillajs-datepicker';
import fr from 'vanillajs-datepicker/js/i18n/locales/fr.js';

Object.assign(Datepicker.locales, fr);

// https://mymth.github.io/vanillajs-datepicker/#/options?id=beforeshowday
function beforeShowDay(date) {
  return date >= new Date(new Date().toDateString());
}

function getMonthRange(date) {
  var startOfRange = getBeginningOfMonth(date);
  startOfRange = getBeginningOfWeek(startOfRange);

  var endOfRange = new Date(startOfRange);
  const six_weeks = 6 * 7;
  endOfRange.setDate(startOfRange.getDate() + six_weeks - 1);

  return [getISODate(startOfRange), getISODate(endOfRange)];
}

function getBeginningOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getBeginningOfWeek(date) {
  const newDate = new Date(date);
  const currentDay = date.getDay();
  const diff = currentDay == 0 ? 6 : currentDay - 1;

  newDate.setDate(date.getDate() - diff);

  return newDate;
}

function getEndOfWeek(date) {
  const newDate = new Date(date);
  const currentDay = date.getDay();
  const diff = (7 - currentDay) % 7;

  newDate.setDate(date.getDate() + diff);

  return newDate;
}

function getISODate(date) {
  if (!date) return;

  var pad = function(num) {
    return (num < 10 ? '0' : '') + num;
  };
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
}

function loadBookingDatePicker(elem) {
  if (!elem || elem.datepicker) return;

  elem.datepicker = new Datepicker(elem, {
    weekStart: 1,
    language: 'fr',
    beforeShowDay: beforeShowDay,
  });

  elem.addEventListener('changeDate', function(e) {
    const date = e.detail.date;

    var event = new CustomEvent('neocamino:booking:dateChange', {
      detail: {
        newDate: getISODate(date),
      },
    });

    this.dispatchEvent(event);
  });

  elem.addEventListener('changeMonth', function(e) {
    var event = new CustomEvent('neocamino:booking:monthChange', {
      detail: {
        newMonth: getMonthRange(e.detail.viewDate),
      },
    });

    this.dispatchEvent(event);
  });

  elem.addEventListener('neocamino:booking:refresh', function(e) {
    const disabledDays = Object.entries(e.detail.days)
      .filter(function(day) {
        return !day[1].length;
      })
      .map(function(day) {
        return Date.parse(day[0]);
      });

    const focusedDate = this.datepicker.getFocusedDate();
    const pickedDate = this.datepicker.getDate();
    const isPickedOnCurrentMonth = pickedDate && focusedDate.getTime() === pickedDate.getTime();

    this.datepicker.setDate({ clear: true, render: false });

    this.datepicker.setOptions({ datesDisabled: disabledDays, defaultViewDate: focusedDate });

    // force slot display if user picked a date on sibling months
    if (isPickedOnCurrentMonth) {
      this.datepicker.setDate(pickedDate);
    }
  });
}

function overrideSelectedDateStyle(elem) {
  if (!elem) return;

  const mainColor = elem.dataset.mainColor;
  const style =
    '<style>.neo-widget { .datepicker-cell.day.selected.focused, .datepicker-cell.day.selected.focused:hover { background-color:' +
    mainColor +
    ';}; }</style>';

  document.head.insertAdjacentHTML('beforeend', style);
}

export function loadBookingDatepickerWithStyle(el) {
  loadBookingDatePicker(el);
  overrideSelectedDateStyle(el);
}

window.loadBookingDatepickerWithStyle = loadBookingDatepickerWithStyle;
