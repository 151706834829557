$(function() {
  showWaitingModal();
});

function showWaitingModal() {
  var modal = $('.waiting-modal');
  if (modal.length == 0) return;

  modal = new bootstrap.Modal(modal, { keyboard: false, backdrop: 'static' });
  modal.show();

  setInterval(refreshWaitingModal, 10000);
}

function refreshWaitingModal() {
  var modal = $('.waiting-modal');
  if (modal.length == 0) return;

  var pollingUrl = modal.data('watcher-polling-url');

  $.getJSON(pollingUrl, function(data) {
    if (data.completed) {
      window.location.reload(true);
    } else {
      modal.find('#waitingQuote').html(data.waiting_quote);
    }
  });
}
