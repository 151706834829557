import { Row, Col } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  margin-bottom: 20px;
`;

export function Section({ children, title, ...rest }) {
  return (
    <SectionWrapper>
      <h5 className='text-muted'>{title}</h5>
      <div className='brief-section-separator'></div>
      {children}
    </SectionWrapper>
  );
}

export function SubSection({ children, title }) {
  return (
    <Row>
      <Col xs={3} className='text-right text-muted'>
        <p>{title}</p>
      </Col>
      <Col xs={9}>{children}</Col>
    </Row>
  );
}
