import { Row, Col } from 'react-bootstrap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import InputColorPicker from './input_color_picker';
import { capitalize, camelToSnakeCase } from './../../lib/utils';
import RestClient from '../../lib/rest_client';
import MultipleChoice from './multiple_choice';
import MultipleUpload from './multiple_upload';
import FontInput from './font_input';
import { FontHelp } from './font_help';
import { FileUpload } from './file_upload';
import { contrastColor, shadeColor } from './colors';

export default class BrandStyleGuide extends Component {
  constructor(props) {
    super(props);
    const { brandStyleGuide } = props;
    this.state = {
      mainColor: brandStyleGuide.main_color || '#2880b8',
      secondaryColor: brandStyleGuide.secondary_color || '#2880b8',
      alternativeColor: brandStyleGuide.alternative_color || '#2880b8',
      iconsStyle: brandStyleGuide.icons_style,
      picturesStyle: brandStyleGuide.pictures_style,
      extraInfos: brandStyleGuide.extra_infos,
      logo: brandStyleGuide.logo.url,
      blackBgLogo: brandStyleGuide.black_bg_logo.url,
      whiteBgLogo: brandStyleGuide.white_bg_logo.url,
      transparentBgLogo: brandStyleGuide.transparent_bg_logo.url,
      favicon: brandStyleGuide.favicon.url,
      titleFont: brandStyleGuide.title_font,
      textFont: brandStyleGuide.text_font,
      pictures: props.pictures,
      displayMainColorPicker: false,
      displaySecondaryColorPicker: false,
      displayAlternativeColorPicker: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    hookAutosize();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  toggleColorPicker = (e) => {
    const InputName = e.target.getAttribute('name');
    const toggleState = `display${capitalize(InputName)}Picker`;
    this.setState({
      [toggleState]: !this.state[toggleState],
    });
  };

  handleColorPickerChange = (color, event) => {
    this.setState({ [color]: event.hex });
  };

  handleColorPickerChangeComplete = (color, event) => {
    let payload = { [camelToSnakeCase(color)]: event.hex };
    payload = this.loadPayload(payload);
    const url = this.props.patchUrl;
    this.setState({ isLoading: true });
    RestClient.patch(url, { brand_style_guide: payload })
      .then((res) => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadPayload = (payload) => {
    const colorKey = Object.keys(payload)[0];
    const color = Object.values(payload)[0];
    if (colorKey === 'main_color') {
      payload.title_color = shadeColor(color, -0.9);
      payload.subtitle_color = shadeColor(color, -0.3);
      payload.background_color = '#FFF';
      payload.form_title_color = contrastColor(color);
      payload.form_background_color = color;
      payload.form_button_background_color = '#FFF';
      payload.form_button_text_color = '#333';
      payload.button_background_color = color;
      payload.button_text_color = contrastColor(color);
    } else if (colorKey === 'secondary_color') {
      payload.secondary_contrast_color = contrastColor(color);
    }
    return payload;
  };

  closePicker = (e) => {
    const toggleState = `display${capitalize(e)}Picker`;
    this.setState({
      [toggleState]: false,
    });
  };

  multipleChoiceUpdate = (e, style, attribute) => {
    const options = this.addOrRemove(style, this.state[attribute]);
    const payload = { [camelToSnakeCase(attribute)]: options };
    this.setState({ isLoading: true, [attribute]: options });
    RestClient.patch(this.props.patchUrl, { brand_style_guide: payload })
      .then((res) => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addOrRemove = (style, iconsStyles) => {
    if (iconsStyles.includes(style)) {
      return iconsStyles.filter((opt) => opt !== style);
    } else {
      return [...iconsStyles, style];
    }
  };

  buildFile = (file) => {
    if (file.name.split('.').length <= 1) {
      throw new Error('File extension missing');
    }
    const fileExtension = file.name.split('.').pop();
    const fileName = `${new Date().getTime()}.${fileExtension}`;
    return new File([file], fileName, { type: file.type });
  };

  logoChange = (e) => {
    const file = this.buildFile(e.target.files[0]);
    const url = this.props.patchUrl;
    const formData = new FormData();
    const inputName = e.target.name;
    formData.append(camelToSnakeCase(inputName), file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.setState({ isLoading: true });
    RestClient.patch(url, formData, config)
      .then((res) => {
        this.setState({
          [inputName]: res.data[camelToSnakeCase(inputName)]['url'],
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  fontBlur = (event) => {
    const { name, value } = event.target;
    const payload = { [camelToSnakeCase(name)]: value };
    this.setState({ isLoading: true });
    RestClient.patch(this.props.patchUrl, { brand_style_guide: payload })
      .then((res) => {
        this.setState({ [name]: value, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitExtraInfos = () => {
    const payload = { extra_infos: this.state.extraInfos };
    this.setState({ isLoading: true });
    RestClient.patch(this.props.patchUrl, { brand_style_guide: payload })
      .then((res) => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleMultipleUpload = (files) => {
    const url = this.props.patchUrl;
    var formData = new FormData();
    files.forEach((file) => {
      const newFile = this.buildFile(file);
      formData.append('pictures[]', newFile);
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    this.setState({ isLoading: true });
    RestClient.patch(url, formData, config).then((res) => {
      this.setState({
        pictures: res.data,
        isLoading: false,
      });
    });
  };

  removePicture = (event, pictureId) => {
    const url = this.props.patchUrl;
    const payload = { delete_picture: pictureId, format: 'js' };
    this.setState({ isLoading: true });
    RestClient.patch(url, payload).then((res) => {
      this.setState({
        pictures: res.data,
        isLoading: false,
      });
    });
  };

  removeLogo = (event, logoType) => {
    const url = this.props.patchUrl;
    const payload = { delete_logo: camelToSnakeCase(logoType), format: 'js' };
    this.setState({ isLoading: true });
    RestClient.patch(url, payload).then((res) => {
      $('#' + logoType).prop('value', '');
      this.setState({
        [logoType]: null,
        isLoading: false,
      });
    });
  };

  render() {
    return (
      <section className='brand-style-guide-container'>
        <ReactTooltip type='dark' effect='solid' className='cta-tooltip' globalEventOff='click' />
        {this.state.isLoading && <div className='fixed-alert alert alert-black'>Enregistrement en cours</div>}

        <div className='colors'>
          <h5 className='text-muted'>Couleurs</h5>
          <div className='brief-section-separator'></div>

          <Row>
            <Col xs={3} className='text-right text-muted'>
              Couleur principale
            </Col>
            <Col xs={9}>
              <InputColorPicker
                color={this.state.mainColor}
                toggleColorPicker={this.toggleColorPicker}
                displayColorPicker={this.state.displayMainColorPicker}
                handleColorPickerChange={(color) => this.handleColorPickerChange('mainColor', color)}
                handleColorPickerChangeComplete={(color) => this.handleColorPickerChangeComplete('mainColor', color)}
                closePicker={this.closePicker}
                name='mainColor'
              />
            </Col>
          </Row>
          <Row className='alternative-color'>
            <Col xs={3} className='text-right text-muted'>
              Couleur secondaire
            </Col>
            <Col xs={9}>
              <InputColorPicker
                color={this.state.alternativeColor}
                toggleColorPicker={this.toggleColorPicker}
                displayColorPicker={this.state.displayAlternativeColorPicker}
                handleColorPickerChange={(color) => this.handleColorPickerChange('alternativeColor', color)}
                handleColorPickerChangeComplete={(color) =>
                  this.handleColorPickerChangeComplete('alternativeColor', color)
                }
                closePicker={this.closePicker}
                name='alternativeColor'
              />
            </Col>
          </Row>
          <Row className='secondary-color'>
            <Col xs={3} className='text-right text-muted'>
              Couleur de bouton
            </Col>
            <Col xs={9}>
              <InputColorPicker
                color={this.state.secondaryColor}
                toggleColorPicker={this.toggleColorPicker}
                displayColorPicker={this.state.displaySecondaryColorPicker}
                handleColorPickerChange={(color) => this.handleColorPickerChange('secondaryColor', color)}
                handleColorPickerChangeComplete={(color) =>
                  this.handleColorPickerChangeComplete('secondaryColor', color)
                }
                closePicker={this.closePicker}
                name='secondaryColor'
              />
            </Col>
          </Row>
        </div>
        <div className='images'>
          <h5 className='text-muted'>Images</h5>
          <div className='brief-section-separator'></div>

          <Row>
            <Col xs={3} className='text-right text-muted'>
              Style des photos
            </Col>
            <Col xs={9}>
              <MultipleChoice
                options={this.props.picturesStyleOptions}
                handleChange={this.multipleChoiceUpdate}
                checkedOptions={this.state.picturesStyle}
                type='picturesStyle'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-right text-muted'>
              Style des icones
            </Col>
            <Col xs={9}>
              <MultipleChoice
                options={this.props.iconsStyleOptions}
                handleChange={this.multipleChoiceUpdate}
                checkedOptions={this.state.iconsStyle}
                type='iconsStyle'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-right text-muted'>
              <p className='text-muted text-right' style={{ position: 'relative', top: '5px' }}>
                Logo
              </p>
            </Col>
            <Col xs={9}>
              <FileUpload
                picture={this.state.logo}
                name='logo'
                logoChange={this.logoChange}
                removeLogo={this.removeLogo}
              />
            </Col>
          </Row>
        </div>
        <div className='police'>
          <h5 className='text-muted'>Police</h5>
          <div className='brief-section-separator'></div>
          <FontInput
            field='titre'
            fontValue={this.state.titleFont}
            handleInputChange={this.handleInputChange}
            fontBlur={this.fontBlur}
            fieldName='titleFont'
          />
          <br />
          <FontInput
            field='texte'
            fontValue={this.state.textFont}
            handleInputChange={this.handleInputChange}
            fontBlur={this.fontBlur}
            fieldName='textFont'
          />
          <br />
          <FontHelp />
          <br />
        </div>
        <div className='clearfix'></div>
        <div className='visual-resources'>
          <h5 className='text-muted'>Ressources visuelles</h5>
          <div className='brief-section-separator'></div>

          <Row>
            <Col xs={3} className='text-right text-muted'>
              <p className='text-muted text-right' style={{ position: 'relative', top: '5px' }}>
                Logo sur fond noir
              </p>
            </Col>
            <Col xs={9}>
              <FileUpload
                picture={this.state.blackBgLogo}
                name='blackBgLogo'
                logoChange={this.logoChange}
                removeLogo={this.removeLogo}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-right text-muted'>
              <p className='text-muted text-right' style={{ position: 'relative', top: '5px' }}>
                Logo sur fond blanc
              </p>
            </Col>
            <Col xs={9}>
              <FileUpload
                picture={this.state.whiteBgLogo}
                name='whiteBgLogo'
                logoChange={this.logoChange}
                removeLogo={this.removeLogo}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-right text-muted'>
              <p className='text-muted text-right' style={{ position: 'relative', top: '5px' }}>
                Logo sur fond transparent
              </p>
            </Col>
            <Col xs={9}>
              <FileUpload
                picture={this.state.transparentBgLogo}
                name='transparentBgLogo'
                logoChange={this.logoChange}
                removeLogo={this.removeLogo}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-right text-muted'>
              <p className='text-muted text-right' style={{ position: 'relative', top: '5px' }}>
                Favicon
              </p>
            </Col>
            <Col xs={9}>
              <FileUpload
                picture={this.state.favicon}
                name='favicon'
                logoChange={this.logoChange}
                removeLogo={this.removeLogo}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='text-right text-muted'>
              Images et illustrations
            </Col>
            <Col xs={9}>
              <MultipleUpload
                pictures={this.state.pictures}
                removePicture={this.removePicture}
                handleMultipleUpload={this.handleMultipleUpload}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3} className='text-right text-muted'>
              Informations supplémentaires
            </Col>
            <Col xs={9}>
              <textarea
                value={this.state.extraInfos}
                className='form-control grey-color-input autosize'
                onChange={this.handleInputChange}
                name='extraInfos'
                onBlur={this.submitExtraInfos}
              ></textarea>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

BrandStyleGuide.propTypes = {
  patchUrl: PropTypes.string,
  brandStyleGuide: PropTypes.object,
  iconsStyleOptions: PropTypes.array,
  picturesStyleOptions: PropTypes.array,
  pictures: PropTypes.array,
};
