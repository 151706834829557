import React from 'react';
import StepForm from '../../components/step_form';
import { NcInput, NcSelect } from '../../../../shared_components/neocamino/inputs';
import { If } from '../../helpers';

export default function CompanyStep({ values, helpers, novice, isAdmin, ...rest }) {
  const companyPositionInput = (
    <NcInput name='company_position' type='text' label="Votre rôle au sein de l'entreprise" />
  );

  const youSectionNoviceInputs = (
    <>
      <NcInput
        name='professional_experience'
        type='text'
        label='Votre expérience professionnelle'
        helpText='Dernier travail exercé ou étude'
      />

      <NcInput
        name='project_genesis'
        type='text'
        componentClass='textarea'
        label="Qu'est-ce qui vous a amené à développer ce projet d'entreprise ?"
      />
    </>
  );

  const companySectionLaunchedInputs = (
    <NcSelect
      name='account_attributes[nb_employees_range_id]'
      label="Le nombre de salariés dans l'entreprise"
      type='select'
      options={helpers.nb_employees_range}
    />
  );

  const missionHelpText =
    "La raison d'être de votre entreprise, c'est votre mission. Elle explique à quoi sert " +
    "votre société et pourquoi vous l'avez créée.\n\nEx : La Cordée (espace de Coworking) : " +
    "Développer l'entraide entre les travailleurs.";

  const visionHelpText =
    "Quel sera l'état futur de votre entreprise ? L'ambition de votre entreprise exprime " +
    "où vous allez. Décrivez-nous où est-ce que vous vous voyez d'ici 3 à 10 ans. " +
    'Essayez de compléter la phrase : " Notre ambition est de devenir ...."\n\n' +
    'Ex : Microsoft (1975) : Mettre un ordinateur sur tous les bureaux avec Windows dessus.';

  return (
    <StepForm name='Vous et votre entreprise' values={values} isAdmin={isAdmin} {...rest}>
      <NcInput name='account_attributes[id]' type='hidden' />
      <NcInput name='marketing_canvas_attributes[id]' type='hidden' />

      <h3 className='m-t-0'>Vous</h3>

      <NcInput name='account_attributes[company_name]' label='Le nom de votre entreprise' type='text' />

      <If condition={novice} then={youSectionNoviceInputs} else={companyPositionInput} />

      <h3>Votre activité</h3>

      <NcSelect
        name='account_attributes[business_ages_range_id]'
        label="L'ancienneté de l’entreprise"
        type='select'
        options={helpers.business_ages_range}
        helpText={novice && 'Si votre entreprise est en cours de création, choisissez “0-1 an”.'}
      />

      <If condition={!novice} then={companySectionLaunchedInputs} />

      <NcInput
        name='project_supervisors'
        label='Qui est impliqué sur le numérique et la communication ?'
        type='text'
        componentClass='textarea'
        helpText='Précisez les noms et rôles de chacun'
      />

      <NcInput
        name='project_decision'
        label='Qui est décisionnaire ?'
        type='text'
        componentClass='textarea'
      />

      <NcInput
        name='project_op_supervisor'
        label='Qui est responsable opérationnel ?'
        type='text'
        componentClass='textarea'
      />

      <NcInput
        name='project_op_contributor'
        label='Qui intervient dans la mise en place opérationnelle (en interne et/ou externe) ?'
        type='text'
        componentClass='textarea'
      />

      <NcInput
        name='roadmap_supervisors'
        label="Qui est en charge du déroulement opérationnel du plan d'action ?"
        type='text'
        componentClass='textarea'
        helpText='Préciser si le ou les responsables sont salariés, en alternance, externes en freelance, autre.'
      />

      <NcInput
        name='marketing_canvas_attributes[mission]'
        label="Votre raison d'être"
        type='text'
        componentClass='textarea'
        helpText={missionHelpText}
        placeholder="Notre mission est …"
      />

      <NcInput
        name='marketing_canvas_attributes[vision]'
        label='Votre ambition'
        type='text'
        componentClass='textarea'
        helpText={visionHelpText}
        placeholder="Notre ambition est …"
      />
    </StepForm>
  );
}
