import RestClient from "../lib/rest_client";
import { get } from 'lodash';

export default class DiagnosticService {
  constructor(urls) {
    this.urls = urls;
  };

  updateDiagnostic(diagnostic) {
    const cleanDiagnostic = this.cleanupAttributes(diagnostic);

    return RestClient.patch(this.urls.patchURL, { diagnostic: cleanDiagnostic });
  };

  cleanupAttributes = (diagnostic) => {
    const newDiagnostic = Object.assign({}, diagnostic);

    const buyerPersonasAttributes = get(newDiagnostic, 'account_attributes.buyer_personas_attributes');

    if(buyerPersonasAttributes && buyerPersonasAttributes.length === 0) {
      delete newDiagnostic.account_attributes.buyer_personas_attributes;
    }

    return newDiagnostic;
  }
}
