import React from 'react';
import { NcButton, NcInput } from '../../../shared_components/neocamino/inputs';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';

export default function FieldArray({
  name,
  label,
  helpText,
  addLabel,
  inputPlaceholder,
  onBlurFn,
  onRemoveFn,
  removeLabel = 'Supprimer',
}) {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const onRemove = (index) => {
    return onRemoveFn(index).then(() => {
      remove(index);
    });
  };

  const onAppend = () => {
    append({ name });
  };

  const onBlur = (e) => {
    onBlurFn(e, getValues({ nest: true }));
  };

  return (
    <>
      <strong>{label}</strong>
      {helpText && <Form.Text>{helpText}</Form.Text>}

      <NcInput name={'account_attributes[id]'} type='hidden' />
      <NcInput name={`${name}[-1].name`} type='hidden' defaultValue='' />

      {fields.map((item, index) => (
        <div key={item.id}>
          <NcInput
            componentClass='textarea'
            name={`${name}[${index}].name`}
            onBlur={onBlur}
            placeholder={inputPlaceholder ? `${inputPlaceholder}${index + 1}` : null}
          />
          <NcButton
            type='button'
            text={removeLabel}
            onClick={() => {
              onRemove(index);
            }}
          />

          <hr />
        </div>
      ))}

      <section>
        <NcButton text={addLabel} type='button' onClick={onAppend} />
      </section>
    </>
  );
}
