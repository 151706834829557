$(function() {
  $(document).on('change', 'input.other_button_input', function() {
    var input = $(this);
    var value = input.val();
    var other_field = input.closest('.radio_buttons_other_form').find('.other_text_input');
    if (value == 'Autre') {
      other_field.removeClass('hidden');
    } else {
      other_field.addClass('hidden');
    }
  });
});
