import React, { Component } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import styled from 'styled-components';

const Input = styled.input`
  padding-right: 34px;
`;

const WordCounter = styled.div`
  color: ${(props) => (props.subjectLength > 77 ? '#b94a48' : '')};
`;

export default class EmailingCampaignFormSubjectInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: this.props.data.subject,
      displayPicker: false,
    };
  }

  onInputChange = (e) => {
    this.setState({ subject: e.target.value });
  };

  addEmoji = (e) => {
    let subject = this.state.subject;
    subject += e.native;

    let input = document.getElementById('emailing_campaign_subject');
    input.focus();

    this.setState({ subject: subject, displayPicker: false });

    setTimeout(() => {
      let form = input.closest('form');
      form.dispatchEvent(new Event("change"));
    }, 500);
  };

  toggleEmojiPicker = () => {
    this.setState({ displayPicker: !this.state.displayPicker });
  };

  render() {
    const subjectLength = this.state.subject ? this.state.subject.length : 0;
    return (
      <section className='form-group'>
        <Input
          type='text'
          name='emailing_campaign[subject]'
          id='emailing_campaign_subject'
          className='form-control string optional annotator-input'
          value={this.state.subject || ''}
          data-annotator-input-title='Objet'
          onChange={this.onInputChange}
        />
        <span className='icon-smile-picker' onClick={this.toggleEmojiPicker}>
          <i className='far fa-smile'></i>
        </span>
        {this.state.displayPicker && <Picker onSelect={this.addEmoji} />}
        <WordCounter className='text-muted text-right' subjectLength={subjectLength}>
          {subjectLength} {subjectLength < 2 ? 'caractère' : 'caractères'}
        </WordCounter>
      </section>
    );
  }
}
