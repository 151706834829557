import { Row, Col } from 'react-bootstrap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FontHelpWrapper = styled.div`
  border-top: 1px solid #ddd !important;
`;

const FontHelpText = styled.p`
  padding: 7px;
  font-size: 12px;
`;

export default class FontInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row>
        <Col xs={3} className='text-right text-muted'>
          <p className='text-muted text-right'>Police {this.props.field}</p>
        </Col>
        <Col xs={9}>
          <input
            type='text'
            className='form-control grey-color-input'
            value={this.props.fontValue}
            onChange={this.props.handleInputChange}
            name={this.props.fieldName}
            onBlur={this.props.fontBlur}
          />
          <FontHelpWrapper className='grey-color-input'>
            <FontHelpText className='text-muted'>
              Vous pouvez lister plusieurs polices, par ordre de préférence, séparées par des virgules.
            </FontHelpText>
          </FontHelpWrapper>
        </Col>
      </Row>
    );
  }
}

FontInput.propTypes = {
  fontBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  fontValue: PropTypes.string,
  field: PropTypes.string,
  fieldName: PropTypes.string,
};
