import GutterAnnotator from '../../services/gutter_annotator';

document.addEventListener('DOMContentLoaded', () => {
  const annotatorTargetId = 'white_paper-brief--annotator-target';
  const node = document.getElementById(annotatorTargetId);

  if (node == null) {
    return;
  }

  const settings = white_paperBriefAnnotatorSettings;
  const formClass = '.white_paper-brief-form';
  const cocoonParentSelector = false;

  let annotator = new GutterAnnotator(node, settings, formClass, cocoonParentSelector);

  annotator.render();
});
