import { Alert, ProgressBar } from 'react-bootstrap';
import React from 'react';

export default function GlobalProgressBar({ progress, isAutoSaving, errorMessage, clearErrorFn }) {
  let content;

  if (errorMessage) {
    content = (
      <Alert variant='danger' onDismiss={clearErrorFn}>
        {errorMessage}
      </Alert>
    );
  } else if (isAutoSaving) {
    content = <p className='text-center diagnostic--global-progress--saving'>Enregistrement en cours…</p>;
  } else {
    content = (
      <>
        <p>Votre progression :</p>
        <ProgressBar now={progress} label={`${progress} %`} />
      </>
    );
  }

  return (
    <div className='nc-step--container diagnostic--global-progress'>
      <div className='nc-step--infos'>{content}</div>
    </div>
  );
}
