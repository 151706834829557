import { Row, Col } from 'react-bootstrap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardIcon, CardText, CardCheckIcon } from './shared/styled_components';

const Picture = styled.img`
  width: ${(props) => (props.type === 'picturesStyle' ? '100%' : '100px')};
  ${(props) => (props.type === 'picturesStyle' ? 'max-width: 155px' : '')};
`;

export default class MultipleChoice extends Component {
  constructor(props) {
    super(props);
  }

  isOptionChecked = (option) => {
    return this.props.checkedOptions.includes(option);
  };

  render() {
    return (
      <Row>
        {this.props.options.map((style, index) => (
          <Col md={4} sm={6} key={index}>
            <Card
              onClick={(e) => this.props.handleChange(e, style.name, this.props.type)}
              checked={this.isOptionChecked(style.name)}
            >
              <CardIcon>
                <Picture type={this.props.type} height='100px' src={style.url} />
              </CardIcon>
              <CardText>
                {this.isOptionChecked(style.name) && <CardCheckIcon className='fas fa-check-square'></CardCheckIcon>}
                <p>{style.name}</p>
              </CardText>
            </Card>
          </Col>
        ))}
      </Row>
    );
  }
}

MultipleChoice.propTypes = {
  checkedOptions: PropTypes.array,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  type: PropTypes.string,
};
