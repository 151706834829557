import React from 'react';
import FinalStep from '../components/steps/final_step';
import Step from './step';

export default class Introduction extends Step {
  component = () => <FinalStep />;

  values = () => {
    return {
      neocamino_expectation: this.diagnostic.neocamino_expectation,
      comments: this.diagnostic.comments,
    };
  };
}
