import "core-js/modules/es.set";
import "core-js/modules/es.map";

import React from "react";
import ReactDOM from "react-dom";
import AppSearchBox from "../../components/app_search_box";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById('appSearchBox');

  if (node !== null) {
    const data = JSON.parse(node.dataset.json);
    ReactDOM.render(<AppSearchBox data={ data } />, node);
  }
});
