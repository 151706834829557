import React from 'react';
import { setYupLocale } from '../../shared_components/neocamino/validations';
import {
  CommunicationActions,
  Company,
  CustomersAndOffers,
  Final,
  Introduction,
  ResourcesAndSkills,
  ResultsAndObjectives,
  Services,
} from './models';

export default class PrediagConfig {
  static ERRORS = {
    HTTP_GENERIC_ERROR: 'Une erreur est survenue, veuillez vérifier vos informations et réessayer.',
  };

  static getSteps = (diagnostic, isNovice, isAdmin) => {
    const account = diagnostic.account;

    setYupLocale();

    return [
      new Introduction(diagnostic, account, isNovice),
      new Company(diagnostic, account, isNovice, isAdmin),
      new CustomersAndOffers(diagnostic, account, isNovice, isAdmin),
      new ResultsAndObjectives(diagnostic, account, isNovice, isAdmin),
      new CommunicationActions(diagnostic, account, isNovice, isAdmin),
      new ResourcesAndSkills(diagnostic, account, isNovice, isAdmin),
      new Services(diagnostic, account, isNovice, isAdmin),
      new Final(diagnostic, account, isNovice, isAdmin),
    ];
  };

  static stepValues = (diagnostic, stepID, isNovice, isAdmin) => {
    const availableSteps = PrediagConfig.getSteps(diagnostic, isNovice, isAdmin);

    return availableSteps[stepID].values();
  };
}
