export default class PrediagHelpers {
  static checkedValueForForm = (value) => {
    if (value || value == false) {
      return value.toString();
    }

    return value;
  };

  static fieldValuesSplit = (field, defaultIfEmpty = [], separator = '\r\n') => {
    if (!field) {
      return defaultIfEmpty;
    }

    const values = field.split(separator);
    if (values.length > 0) {
      return values.map((item) => {
        return { name: item };
      });
    }

    return defaultIfEmpty;
  };

  static valuesCompletionPercent = (values) => {
    const availableFormValues = PrediagHelpers.flattenObject(values);
    const availableFormValuesValues = Object.values(availableFormValues);

    if (availableFormValuesValues.length === 0) {
      return 0;
    }

    const filledValues = availableFormValuesValues.filter((value) => {
      return value && (typeof value == 'number' || typeof value == 'boolean' || value.length > 0);
    });

    return Math.trunc((filledValues.length / availableFormValuesValues.length) * 100);
  };

  // @note From https://stackoverflow.com/a/55251598
  static flattenObject = (object) => {
    const flattened = {};

    Object.keys(object).forEach((key) => {
      if (typeof object[key] === 'object' && object[key] !== null) {
        Object.assign(flattened, PrediagHelpers.flattenObject(object[key]));
      } else {
        flattened[key] = object[key];
      }
    });

    return flattened;
  };

  static emptyStringToNull = (value, originalValue) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
  };
}

export const If = (props) => {
  const condition = props.condition || false;
  const positive = props.then || null;
  const negative = props.else || null;

  return condition ? positive : negative;
};
