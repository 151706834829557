import analytics from 'analytics/shared';

var loadMetrics = function() {
  document.addEventListener('click', function(e) {
    const metric = e.target.closest(analytics.selectors.METRIC);
    if (!metric) return;

    const container = metric.closest(analytics.selectors.ASYNC_METRICS_CONTAINER);
    const all_metrics = container.querySelectorAll(analytics.selectors.METRIC);
    const siblings = Array.from(all_metrics).filter(function (sibling) {
      return sibling !== metric;
    });

    siblings.forEach(metric => {
      metric.querySelector(analytics.selectors.METRIC_BTN)
        .classList.remove('active');
    });
  });
}

document.addEventListener('DOMContentLoaded', loadMetrics);
