import analytics from 'analytics/shared';

//*** Helpers ***
var showChart = function(container) {
  container.classList.remove('hidden');
}

var hideChart = function(container) {
  container.classList.add('hidden');
}

// *** Listeners ***
var onDateChange = function(e) {
  const chartContainer = e.target;

  hideChart(chartContainer);

  chartContainer.dataset.asyncExtraParams = JSON.stringify(e.detail);
}

var onHideChart = function(e) {
  const chartContainer = e.target;

  hideChart(chartContainer);
}

var onRefreshChart = function(e) {
  const chartContainer = e.target;

  var existingParams = chartContainer.dataset.asyncExtraParams;
  existingParams &&= JSON.parse(existingParams);

  const mergedParams = {
    ...existingParams,
    ...e.detail
  };

  chartContainer.dataset.asyncExtraParams = JSON.stringify(mergedParams);

  showChart(chartContainer);

  var event = new Event('refresh');
  chartContainer.dispatchEvent(event);
}

var hookAsyncEvents = function() {
  const els = document.querySelectorAll(analytics.selectors.ASYNC_CHART_CONTAINER);

  els.forEach((container) => {
    container.addEventListener(analytics.events.CHART_REFRESH, onRefreshChart);
    container.addEventListener(analytics.events.CHART_HIDE, onHideChart);
    container.addEventListener(analytics.events.CHART_DATECHANGE, onDateChange);
  });
}

document.addEventListener('DOMContentLoaded', hookAsyncEvents);
