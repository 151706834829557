import { Alert } from 'react-bootstrap';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SingleChoice } from './single_choice';
import { MultipleChoice } from './multiple_choice';
import { SingleDynamicInput } from './single_dynamic_input';
import { MultipleDynamicInput } from './multiple_dynamic_input';
import { DomainInput } from './domain_input';
import {
  baseFeaturesIcons,
  additionalFeaturesIcons,
  websiteTypeIcons,
  projectTypeIcons,
  hostingIcons,
} from './icons';
import { Section, SubSection } from '../../shared_components/briefs';

export default function WebsiteBriefForm({
  websiteTypeOptions,
  projectTypeOptions,
  websiteEditorOptions,
  hostingOptions,
  baseFeaturesOptions,
  additionalFeaturesOptions,
  websiteBrief,
  submitForm,
  isLoading,
  pictures,
}) {
  const { register, watch, getValues, handleSubmit, control } = useForm({
    defaultValues: {
      secondaryDomains: websiteBrief.secondary_domains,
      extraFeatures: websiteBrief.extra_features,
      pages: websiteBrief.pages,
      likes: websiteBrief.likes,
      dislikes: websiteBrief.dislikes,
    },
  });
  const websiteTypeState = watch('websiteType', websiteBrief.website_type);
  const projectTypeState = watch('projectType', websiteBrief.project_type);
  const websiteEditorState = watch('websiteEditor', websiteBrief.website_editor);
  const hostingState = watch('hosting', websiteBrief.hosting);
  const baseFeaturesState = watch('baseFeatures', websiteBrief.base_features);
  const additionalFeaturesState = watch('additionalFeatures', websiteBrief.additional_features);
  const domainNameState = watch('domainName', websiteBrief.domain_name);
  const previousDomainNameState = watch('previousDomainName', websiteBrief.previous_domain_name);
  const secondaryDomainsState = watch('secondaryDomains', websiteBrief.secondary_domains);
  const likesState = watch('likes', websiteBrief.likes);
  const dislikesState = watch('dislikes', websiteBrief.dislikes);

  const handleChange = (e) => {
    const data = getValues({ nest: true });
    submitForm(data);
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <section className='website-brief'>
      {isLoading && <div className='fixed-alert alert alert-black'>Enregistrement en cours</div>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Section title='Votre projet de site'>
          <SubSection title='Type de site'>
            <SingleChoice
              options={websiteTypeOptions}
              selected={websiteTypeState}
              register={register}
              inputName='websiteType'
              handleChange={handleChange}
              pictures={websiteTypeIcons}
            />
          </SubSection>
          <SubSection title='Type de projet'>
            <SingleChoice
              options={projectTypeOptions}
              selected={projectTypeState}
              register={register}
              inputName='projectType'
              handleChange={handleChange}
              pictures={projectTypeIcons}
            />
          </SubSection>
        </Section>
        <Section title='Décider des bases de votre site'>
          <SubSection title='Nom de domaine du futur site'>
            <DomainInput
              inputName='domainName'
              register={register}
              defaultValue={websiteBrief.domain_name}
              handleChange={handleChange}
              inputState={domainNameState}
            />
          </SubSection>
          <SubSection title='Nom de domaine du site à refondre'>
            <DomainInput
              inputName='previousDomainName'
              register={register}
              defaultValue={websiteBrief.previous_domain_name}
              handleChange={handleChange}
              inputState={previousDomainNameState}
            />
          </SubSection>
          <SubSection title='Noms de domaine secondaires'>
            <SingleDynamicInput
              register={register}
              handleChange={handleChange}
              control={control}
              inputName='secondaryDomains'
              withLink={true}
              currentState={secondaryDomainsState}
            />
          </SubSection>
          <SubSection title='Editeur de site'>
            <SingleChoice
              options={websiteEditorOptions}
              selected={websiteEditorState}
              register={register}
              inputName='websiteEditor'
              handleChange={handleChange}
              pictures={pictures.website_editor}
              imageType='picture'
            />
          </SubSection>
          <SubSection title='Hébergement et maintenance'>
            <SingleChoice
              options={hostingOptions}
              selected={hostingState}
              register={register}
              inputName='hosting'
              handleChange={handleChange}
              pictures={hostingIcons}
            />
            <Alert variant='info'>
              Votre site doit être sur un hébergement rapide, mis à jour et sauvegardé régulièrement et capable
              d'être restauré en cas de problème technique ou piratage.
            </Alert>
          </SubSection>
        </Section>
        <Section title='Les fonctionnalités'>
          <SubSection title='Fonctionnalités de base'>
            <MultipleChoice
              options={baseFeaturesOptions}
              selected={baseFeaturesState}
              register={register}
              inputName='baseFeatures'
              handleChange={handleChange}
              icons={baseFeaturesIcons}
            />
          </SubSection>
          <SubSection title='Fonctionnalités additionnelles'>
            <MultipleChoice
              options={additionalFeaturesOptions}
              selected={additionalFeaturesState}
              register={register}
              inputName='additionalFeatures'
              handleChange={handleChange}
              icons={additionalFeaturesIcons}
            />
          </SubSection>
          <SubSection title='Autres fonctionnalités'>
            <SingleDynamicInput
              register={register}
              handleChange={handleChange}
              control={control}
              inputName='extraFeatures'
              withLink={false}
            />
          </SubSection>
        </Section>
        <Section title='Arborescence'>
          <MultipleDynamicInput
            register={register}
            handleChange={handleChange}
            control={control}
            inputName='pages'
            withLink={false}
            attributes={{ title: 'Nom', body: 'Description' }}
          />
        </Section>
        <Section title="Inspiration : des sites existants que j'apprécie">
          <MultipleDynamicInput
            register={register}
            handleChange={handleChange}
            control={control}
            inputName='likes'
            withLink={true}
            attributes={{ title: 'Lien', body: "Ce que j'apprécie" }}
            currentState={likesState}
          />
        </Section>
        <Section title="Inspiration : ce que je n'apprécie pas sur des sites">
          <MultipleDynamicInput
            register={register}
            handleChange={handleChange}
            control={control}
            inputName='dislikes'
            withLink={true}
            attributes={{ title: 'Lien', body: "Ce que je n'apprécie pas" }}
            currentState={dislikesState}
          />
        </Section>
        <br />
        <br />
        {/* <input type="submit" action="javascript:void(0);" /> */}
      </form>
    </section>
  );
}
