export default class AnnotationService {
  constructor(urls, author) {
    this.urls = urls;
    this.author = author;
  }

  createAnnotation(annotation, successFn, failFn) {
    annotation['annotation[author_id]'] = this.author.id;
    annotation['annotation[author_type]'] = this.author.type;

    return $.ajax({
      type: 'POST',
      url: this.urls.createURL,
      data: annotation,
      headers: csrfHeader(),
      dataType: 'json',
      success: successFn,
    }).fail(failFn);
  }

  resolveAnnotations(field_name, successFn, errorFn) {
    return $.ajax({
      type: 'PATCH',
      url: this.urls.resolveURL,
      data: { field_name: field_name },
      headers: csrfHeader(),
      dataType: 'text',
      success: successFn,
      error: errorFn,
    });
  }

  deleteAnnotation(annotationID, successFn, errorFn) {
    const annotationDeleteURL = this.urls.deleteURL.replace('_annotation_id_', annotationID);

    return $.ajax({
      type: 'DELETE',
      url: annotationDeleteURL,
      headers: csrfHeader(),
      dataType: 'text',
      success: successFn,
      error: errorFn,
    });
  }

  canDeleteAnnotation(annotation) {
    return parseInt(this.author.id) === annotation.author_id && this.author.type === annotation.author_type;
  }

  canCreateNote() {
    return this.author.type === 'Admin';
  }

  listAnnotations(successFn, failFn) {
    return $.getJSON(this.urls.listURL, successFn).fail(failFn);
  }
}

function csrfHeader() {
  var csrf_token = $('meta[name=csrf-token]').attr('content');
  if (csrf_token !== undefined) {
    return { 'X-CSRF-Token': csrf_token };
  } else {
    return {};
  }
}
