$(function() {
  $(document).on('change', 'input.netlinking-actions-boolean', function() {
    var input = $(this);
    var value = input.val();
    var otherField = input.closest('.netlinking-actions').find('.netlinking-actions-text');
    if (value == 'true') {
      otherField.removeClass('hidden');
    } else {
      otherField.addClass('hidden');
    }
  });
});
