import React from 'react';
import StepForm from '../../components/step_form';
import { NcInput, NcRadioList } from '../../../../shared_components/neocamino/inputs';
import { If } from '../../helpers';
import FieldArray from '../../components/field_array';
import BuyerPersonasFields from '../field_arrays/buyer_personas_fields';

export default function CustomersAndOffersStep({
  stepID,
  values,
  isAdmin,
  helpers,
  onAutoSaveFn,
  diagnosticUpdateFn,
  ...rest
}) {
  const serviceSellsFocusInput = (
    <>
      <br/>
      <NcInput
        name='service_sells_focus'
        type='text'
        componentClass='textarea'
        label='Sur quelle activité souhaitez-vous développer les ventes en priorité (si plusieurs activités) ?'
        className='admin'
      />
    </>
  );

  const customersFocusInput = (
    <>
      <br/>
      <NcInput
        name='customers_focus'
        type='text'
        componentClass='textarea'
        label='Sur quelle cible souhaitez-vous développer les ventes en priorité ? '
        className='admin'
      />
    </>
  );


  const promiseHelpText =
    'La promesse est le bénéfice matériel ou immatériel que vous allez proposer à vos acheteurs.' +
    " C'est le principal avantage des produits/services que vous proposez ; la principale raison de faire appel à vous.";

  const websitesCompetingHelpText =
    "Inscrivez le site d'une entreprise qui propose des services ou des" +
    ' produits similaires aux vôtres et qui est une alternative pour vos clients.';

  const saveFieldArrayKey = (e, values) => {
    e.preventDefault();
    e.stopPropagation();

    return onAutoSaveFn(stepID, values);
  };

  const removeFieldArrayKey = (inputName, inputIndex) => {
    let attributes = Object.assign({}, values);
    attributes.account_attributes[inputName].splice(inputIndex, 1);

    return onAutoSaveFn(stepID, attributes);
  };

  // @note We're bypassing form validation too
  const saveBuyerPersona = (e, values) => {
    e.preventDefault();
    e.stopPropagation();

    return onAutoSaveFn(stepID, values);
  };

  const removeBuyerPersona = (inputIndex) => {
    let attributes = Object.assign({}, values);
    attributes.account_attributes.buyer_personas_attributes[inputIndex]['_destroy'] = '1';

    return onAutoSaveFn(stepID, attributes);
  };

  return (
    <StepForm
      name='Vos clients et votre offre'
      stepID={stepID}
      values={values}
      onAutoSaveFn={onAutoSaveFn}
      isAdmin={isAdmin}
      {...rest}
    >
      <h3 className='m-t-0'>Votre offre</h3>

      <NcInput
        name='offer_synthesis'
        label='Que proposez-vous en une phrase et à qui ?'
        type='text'
        componentClass='textarea'
        placeholder='Je propose … à …'
      />

      <NcInput name='marketing_canvas_attributes[id]' type='hidden' />
      <NcInput
        name='marketing_canvas_attributes[promise]'
        label='Quelle est votre promesse principale ?'
        type='text'
        componentClass='textarea'
        helpText={promiseHelpText}
      />

      <FieldArray
        label='Quels sont vos produits et services ?'
        name='account_attributes[offer_values]'
        type='fieldArray'
        addLabel='Ajouter un produit ou service'
        inputPlaceholder='Mon produit ou service n°'
        onRemoveFn={(index) => {
          return removeFieldArrayKey('offer_values', index);
        }}
        onBlurFn={saveFieldArrayKey}
      />

      <If condition={isAdmin} then={serviceSellsFocusInput} />

      <h3>Vos clients</h3>

      <NcRadioList
        name='customers_location'
        label='La localisation de vos clients'
        options={helpers.customers_location}
      />

      <NcInput
        name='customers_location_description'
        type='text'
        componentClass='textarea'
        label='Précisez la localisation'
        helpText='Liste de pays, communes…'
      />

      <hr />

      <BuyerPersonasFields
        onRemoveFn={removeBuyerPersona}
        onBlurFn={saveBuyerPersona}
        stateValues={values.account_attributes.buyer_personas_attributes}
        type='buyerPersonas'
        name='buyer_personas'
      />

      <If condition={isAdmin} then={customersFocusInput} />

      <h3>Vos concurrents</h3>

      <FieldArray
        name='account_attributes[websites_competing_values]'
        type='fieldArray'
        addLabel='Ajouter un concurrent'
        label='Qui sont vos principaux concurrents ?'
        helpText={websitesCompetingHelpText}
        inputPlaceholder='Mon concurrent n°'
        onRemoveFn={(index) => {
          return removeFieldArrayKey('websites_competing_values', index);
        }}
        onBlurFn={saveFieldArrayKey}
      />
    </StepForm>
  );
}
