import React, { Component } from 'react';
import ColumnDnd from './column_dnd';
import ColumnPicker from './column_picker';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ColumnPickerWrapper = styled.div`
  border-right: 1px solid #d4d4d4;
`;

export default class ModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
    };
  }

  retrieveColumns = (checkedOption, isChecked) => {
    let columns = [...this.state.columns];
    if (isChecked) {
      columns.push(checkedOption);
    } else {
      columns = columns.filter((column) => column !== checkedOption);
    }
    this.setState({ columns: columns });
    this.patchColumns(columns);
  };

  patchColumns = (columns) => {
    let patchUrl = this.props.patchTableSettingEndpoint;
    $.ajax({
      type: 'PATCH',
      url: patchUrl,
      data: { table_setting: { columns: columns } },
      headers: csrfHeader(),
      dataType: 'json',
    }).done((res) => {
      $.ajax({ type: 'GET', dataType: 'script', url: window.location });
    });
  };

  render() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <ColumnPickerWrapper className='col-sm-6'>
            <ColumnPicker
              columns={this.state.columns}
              contactFields={this.props.contactFields}
              updateParentState={this.retrieveColumns}
              translations={this.props.translations}
            />
          </ColumnPickerWrapper>
          <div className='col-sm-6'>
            <ColumnDnd
              columns={this.state.columns}
              onPatchColumns={(columns) => this.patchColumns(columns)}
              translations={this.props.translations}
            />
          </div>
        </div>
      </div>
    );
  }
}

ModalBody.propTypes = {
  columns: PropTypes.array,
  contactFields: PropTypes.array,
  translations: PropTypes.object,
  patchTableSettingEndpoint: PropTypes.string,
};

function csrfHeader() {
  var csrf_token = $('meta[name=csrf-token]').attr('content');
  if (csrf_token !== undefined) {
    return { 'X-CSRF-Token': csrf_token };
  } else {
    return {};
  }
}
