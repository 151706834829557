import analytics from 'analytics/shared';

// *** Listeners ***
var onRefreshMetrics = function(e) {
  const metricsContainer = e.target;

  metricsContainer.dataset.asyncExtraParams = JSON.stringify(e.detail);

  var event = new Event('refresh');
  metricsContainer.dispatchEvent(event);
}

var onMetricsLoaded = function(e) {
  const container = e.container;

  const activeMetric = $(container).find(analytics.selectors.ACTIVE_METRIC_BTN)
    .closest(analytics.selectors.METRIC);

  if(!activeMetric.length) return;

  const event = new CustomEvent(analytics.events.METRICS_CHANGE, {
    detail: {
      metric: activeMetric[0]
    }
  });
  container[0].dispatchEvent(event);
}

var hookAsyncEvents = function() {
  const els = document.querySelectorAll(analytics.selectors.ASYNC_METRICS_CONTAINER);

  els.forEach((container) => {
    container.addEventListener(analytics.events.METRICS_REFRESH, onRefreshMetrics);
  });

  document.addEventListener('render_async_load', onMetricsLoaded)
}

document.addEventListener('DOMContentLoaded', hookAsyncEvents);
