import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RestClient from './../../lib/rest_client';
import WebsiteBriefForm from './form';
export default class WebsiteBrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  buildPayload = (data) => {
    return {
      website_type: data.websiteType,
      project_type: data.projectType,
      domain_name: data.domainName,
      previous_domain_name: data.previousDomainName,
      secondary_domains: this.cleanDynamicInput(data.secondaryDomains),
      website_editor: data.websiteEditor,
      hosting: data.hosting,
      base_features: this.cleanMultipleChoice(data.baseFeatures),
      additional_features: this.cleanMultipleChoice(data.additionalFeatures),
      extra_features: this.cleanDynamicInput(data.extraFeatures),
      pages: this.cleanDynamicInput(data.pages),
      likes: this.cleanDynamicInput(data.likes),
      dislikes: this.cleanDynamicInput(data.dislikes),
    };
  };

  cleanDynamicInput = (domains) => {
    return domains === undefined ? [] : domains;
  };

  cleanMultipleChoice = (features) => {
    return features.filter((feature) => feature !== false);
  };

  patchForm = (data) => {
    const payload = this.buildPayload(data);
    this.setState({ isLoading: true });
    RestClient.patch(this.props.patchUrl, { website_brief: payload }).then((res) => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { websiteBrief } = this.props;
    return (
      <WebsiteBriefForm
        websiteTypeOptions={this.props.websiteTypeOptions}
        projectTypeOptions={this.props.projectTypeOptions}
        websiteEditorOptions={this.props.websiteEditorOptions}
        hostingOptions={this.props.hostingOptions}
        baseFeaturesOptions={this.props.baseFeaturesOptions}
        additionalFeaturesOptions={this.props.additionalFeaturesOptions}
        websiteBrief={websiteBrief}
        submitForm={this.patchForm}
        isLoading={this.state.isLoading}
        pictures={this.props.pictures}
      />
    );
  }
}

WebsiteBrief.propTypes = {
  patchUrl: PropTypes.string,
  websiteBrief: PropTypes.object,
  websiteTypeOptions: PropTypes.array,
  websiteEditorOptions: PropTypes.array,
  additionalFeaturesOptions: PropTypes.array,
  projectTypeOptions: PropTypes.array,
  baseFeaturesOptions: PropTypes.array,
  hostingOptions: PropTypes.array,
  pictures: PropTypes.object,
};
