import * as yup from 'yup';
import React from 'react';
import PrediagHelpers from '../helpers';
import ResourcesAndSkillsStep from '../components/steps/resources_and_skills_step';
import Step from './step';

export default class ResourcesAndSkills extends Step {
  component = () => <ResourcesAndSkillsStep />;

  sharedValues = () => {
    return {
      will_eval_learning: PrediagHelpers.checkedValueForForm(this.diagnostic.will_eval_learning),
      will_eval_communication: PrediagHelpers.checkedValueForForm(this.diagnostic.will_eval_communication),
      will_eval_knowledge: PrediagHelpers.checkedValueForForm(this.diagnostic.will_eval_knowledge),
      will_eval_motivation: PrediagHelpers.checkedValueForForm(this.diagnostic.will_eval_motivation),
      account_attributes: {
        id: this.diagnostic.account_id,
        annual_budget: this.diagnostic.account.annual_budget,
      },
    };
  };

  adminValues = () => {
    return {
      digital_appetize: this.diagnostic.digital_appetize,
      com_budget:       this.diagnostic.com_budget,
      free_time:        this.diagnostic.free_time
    }
  };

  validationSchema = () => {
    return yup.object().shape({
      account_attributes: yup.object().shape({
        annual_budget: yup
          .number()
          .transform(PrediagHelpers.emptyStringToNull)
          .min(0)
          .nullable(),
      }),
    });
  };
}
