import * as yup from 'yup';
import React from 'react';
import ResultsAndObjectivesStep from '../components/steps/results_and_objectives_step';
import PrediagHelpers from '../helpers';
import Step from './step';

export default class ResultsAndObjectives extends Step {
  component = () => <ResultsAndObjectivesStep />;

  sharedValues = () => {
    return {
      one_year_qualitative_goal: this.diagnostic.one_year_qualitative_goal,
      one_year_quantitative_goal: this.diagnostic.one_year_quantitative_goal,
      monthly_revenues: this.diagnostic.monthly_revenues,
      average_basket: this.diagnostic.average_basket,
      buying_frequency: this.diagnostic.buying_frequency,
      buying_distribution: this.diagnostic.buying_distribution,
      source_distribution: this.diagnostic.source_distribution,
    };
  };

  adminValues = () => {
    return {
      two_year_goal:   this.diagnostic.two_year_goal,
      yearly_revenues: this.diagnostic.yearly_revenues,
      growth_one_year: this.diagnostic.growth_one_year,
      nb_customers:    this.diagnostic.nb_customers,
      periodicity:     this.diagnostic.periodicity,
      star_product:    this.diagnostic.star_product,
      tx_average_transform: this.diagnostic.tx_average_transform,
    }
  };

  noviceValues = () => {
    return {
      service_sells_exist: PrediagHelpers.checkedValueForForm(this.diagnostic.service_sells_exist),
      service_sells_description: this.diagnostic.service_sells_description,
    };
  };

  advancedValues = () => {
    return {
      conversion: this.diagnostic.conversion,
    };
  };

  validationSchema = () => {
    return yup.object().shape({
      buying_frequency: yup.string().max(255),
      monthly_revenues: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .min(0)
        .nullable(),
      average_basket: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .min(0)
        .nullable(),
      nb_customers: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .min(0)
        .nullable(),
      yearly_revenues: yup
        .number()
        .transform(PrediagHelpers.emptyStringToNull)
        .min(0)
        .nullable(),
    });
  };
}
