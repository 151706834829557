import GutterAnnotator from '../../services/gutter_annotator';

document.addEventListener('DOMContentLoaded', () => {
  const annotatorTargetId = 'emailing-campaign--annotator-target';
  const node = document.getElementById(annotatorTargetId);

  if (node == null) {
    return;
  }

  const settings = emailingCampaignAnnotatorSettings;
  const formClass = '.edit_emailing_campaign';

  // Ensure there's no cocoonParentElement to avoid messing up inputs IDs
  // in GutterAnnotator component's logic
  const cocoonParentSelector = false;

  let annotator = new GutterAnnotator(node, settings, formClass, cocoonParentSelector);

  annotator.render();
});
