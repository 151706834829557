import GutterAnnotator from '../../services/gutter_annotator';

document.addEventListener('DOMContentLoaded', () => {
  const annotatorTargetId = 'page-template--annotator-target';
  const node = document.getElementById(annotatorTargetId);

  if (node == null) {
    return;
  }

  const settings = pageTemplateAnnotatorSettings;
  const formClass = '.page-template-form';
  const cocoonParentSelector = '.nested-fields';

  let annotator = new GutterAnnotator(node, settings, formClass, cocoonParentSelector);

  annotator.render();
});
