function selectSlot() {
  const selectedSlot = this;
  const root = bookingRootOf(selectedSlot);

  replaceOrCreateTimeInput(selectedSlot, root);
  changeTimeForBackLink(selectedSlot, root);
  changeTimeForThank(selectedSlot, root);
  displayForm(root);
}

function replaceOrCreateTimeInput(timeSelected, root) {
  var timeInput = timeInputEl(root);

  if (!timeInput) {
    timeInput = createTimeInput(timeSelected);
    bookingForm(root).appendChild(timeInput);
  }

  timeInput.value = timeSelected.dataset.neoSlotFrom;
}

function createTimeInput(timeSelected) {
  var timeInput = document.createElement('input');

  timeInput.classList.add('hidden');
  timeInput.type = 'hidden';
  timeInput.id = 'lp_booking_slot';
  timeInput.name = 'lp[booking_slot]';

  return timeInput;
}

function changeTimeForBackLink(slotSelected, root) {
  var backButtonText = backlinkLabel(root);

  backButtonText.textContent = 'Rdv de ' + slotRangeHumanized(slotSelected) + ' le ' + dateHumanized(root);
}

function slotRangeHumanized(slotSelected) {
  const regexTime = /(\d{2}):(\d{2})/;
  var slotFrom = slotSelected.dataset.neoSlotFrom;
  var slotTo = slotSelected.dataset.neoSlotTo;
  var slotFromTime = slotFrom.match(regexTime)[0];
  var slotToTime = slotTo.match(regexTime)[0];

  return slotFromTime + ' à ' + slotToTime;
}

function changeTimeForThank(timeSelected, root) {
  const popup = popupFrom(root);
  if (!popup) return;

  const thankSlotSpan = bookingThanksSelectedSlotEl(popup);
  if (!thankSlotSpan) return;

  thankSlotSpan.textContent = 'Le ' + dateHumanized(root) + ' à ' + timeSelected.innerText + ' ';
}

function dateHumanized(root) {
  const datepickerElem = bookingDatepickerElem(root);
  var date = datepickerElem.datepicker.getDate('dd/mm');

  return date;
}

function localeStringDate(root) {
  const datepickerElem = bookingDatepickerElem(root);
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  var date = datepickerElem.datepicker.getDate();

  if (!date) return;
  var localeDate = date.toLocaleDateString('fr-FR', options);

  return localeDate;
}

function displayForm(root) {
  hideDatepicker(root);
  hideMobileBacklink(root);
  hideSlotsContainer(root);
  showForm(root);
}

function onDateChangeUpdateSlots(e) {
  const newDate = e.detail.newDate;

  const rootEl = bookingRootOf(this);
  showMobile(rootEl);
  buildSlots(getSlotsOn(newDate, rootEl), rootEl);
  showSlotsContainer(rootEl);
}

function onMonthChangeFetchSlots(e) {
  const root = bookingRootOf(this);
  fetchMonthSlots(e.detail.newMonth, root);
}

function resetMonthSlots(root) {
  delete root.dataset.neoMonthSlots;
  delete root.neoMonthSlots;
}

function getMonthSlots(root) {
  if (root.neoMonthSlots) return root.neoMonthSlots;

  root.neoMonthSlots = JSON.parse(root.dataset.neoMonthSlots || {});
  return root.neoMonthSlots;
}

function setMonthSlots(slots, root) {
  root.dataset.neoMonthSlots = JSON.stringify(slots);
}

function getSlotTemplate(root) {
  return root.dataset.neoSlotTemplate.trim();
}

function getSlotsEndpoint(root) {
  return root.dataset.neoBookingUrl;
}

function getSlotsOn(date, root) {
  return getMonthSlots(root)[date] || [];
}

function fetchMonthSlots(monthRange, root) {
  var params = {
    dates_range: monthRange,
  };

  $.ajax({
    url: getSlotsEndpoint(root),
    cache: false,
    data: params,
    dataType: 'jsonp',
    success: function(data) {
      resetMonthSlots(root);
      setMonthSlots(data.slots, root);
      notifySlotsUpdate(root);
    },
  });
}

function notifySlotsUpdate(root) {
  var event = new CustomEvent('neocamino:booking:refresh', {
    detail: {
      days: getMonthSlots(root),
    },
  });

  bookingDatepickerElem(root).dispatchEvent(event);
}

function buildSlots(slots, root) {
  var bookingSlotsEl = bookingSlots(root);
  var template = getSlotTemplate(root);

  bookingSlotsEl.innerHTML = '';

  for (const slot of slots) {
    const label = formatTime(slot[0]);
    const el = buildSlot(label, slot, template);
    bookingSlotsEl.append(el);
  }

  setSelectedDate(root);
}

function setSelectedDate(root) {
  const selectedDateDiv = bookingSelectedDate(root);
  selectedDateDiv.innerHTML = '';

  const newSpan = document.createElement('span');
  const localeDate = localeStringDate(root);
  if (!localeDate) return;

  const newDate = document.createTextNode(localeDate);
  newSpan.appendChild(newDate);
  selectedDateDiv.append(newSpan);
}

function buildSlot(label, slot, template) {
  const templateEl = document.createElement('template');
  templateEl.innerHTML = template;
  const el = templateEl.content.firstChild;

  const button = el.querySelector('.neo-slot-button');
  button.textContent = label;
  button.dataset.neoSlotFrom = slot[0];
  button.dataset.neoSlotTo = slot[1];

  button.addEventListener('click', selectSlot);

  return el;
}

function formatTime(slot) {
  var date = new Date(slot).toLocaleString('en-US', { timeZone: 'Europe/Paris' });
  date = new Date(date);

  var hours = date.getHours();
  var minutes = date.getMinutes();

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes;
}

// Hide and show components

function showDatepicker(root) {
  const datepickerContainer = bookingDatepickerContainer(root);
  datepickerContainer.classList.add('d-none', 'd-sm-block');
  datepickerContainer.classList.remove('hidden');
}

function hideDatepicker(root) {
  bookingDatepickerContainer(root).classList.add('hidden');
}

function showSlotsContainer(root) {
  bookingSlotsContainer(root).classList.remove('hidden');
}

function hideSlotsContainer(root) {
  bookingSlotsContainer(root).classList.add('hidden');
}

function showForm(root) {
  bookingDatepickerContainer(root).classList.remove('d-none', 'd-sm-block');
  bookingFormContainer(root).classList.remove('hidden');
}

function hideForm(root) {
  bookingFormContainer(root).classList.add('hidden');
}

// helpers

function bookingDatepickerContainer(root) {
  return root.querySelector('.neo-booking-datepicker-container');
}

function bookingDatepickerElem(root) {
  return root.querySelector('.neo-booking-datepicker');
}

function bookingSlotsContainer(root) {
  return root.querySelector('.neo-booking-slots-container');
}

function bookingSlots(root) {
  return root.querySelector('.neo-booking-slots');
}

function bookingRootOnDocument(popupOrPage) {
  return popupOrPage.querySelector('.neo-booking--container');
}

function bookingRootOf(el) {
  return el.closest('.neo-booking--container');
}

function popupFrom(rootEl) {
  return rootEl.closest('.neo-booking-popup');
}

function bookingSelectedDate(root) {
  return root.querySelector('.neo-booking-selected-date');
}

function bookingFormContainer(root) {
  return root.querySelector('.neo-booking-form-container');
}

function bookingForm(root) {
  return bookingFormContainer(root).querySelector('& > form');
}

function timeInputEl(root) {
  return bookingForm(root).querySelector('#lp_booking_slot');
}

function bookingThanksSelectedSlotEl(popup) {
  return popup.querySelector('.booking-thank-selected-slot');
}

function backlinkLabel(root) {
  return bookingBacklink(root).querySelector('.selected-slot');
}

function bookingMobileBacklink(root) {
  return root.querySelector('.neo-booking-mobile-backlink');
}

function bookingBacklink(root) {
  return root.querySelector('.neo-widget-backlink');
}

// Mobile

function showMobileBacklink(root) {
  const backlink = bookingMobileBacklink(root);
  backlink.classList.remove('d-none', 'd-sm-block');
  backlink.classList.add('d-block', 'd-sm-none');
}

function hideMobileBacklink(root) {
  bookingMobileBacklink(root).classList.add('d-none', 'd-sm-block');
}

function hideMobileDatepicker(root) {
  const datepickerContainer = bookingDatepickerContainer(root);
  datepickerContainer.classList.remove('d-block', 'd-sm-none');
  datepickerContainer.classList.add('d-none', 'd-sm-block');
}

function showMobileDatepicker(root) {
  bookingDatepickerContainer(root).classList.remove('d-none', 'd-sm-block');
}

function showMobile(root) {
  hideMobileDatepicker(root);
  showMobileBacklink(root);
}

function backMobileDatepicker() {
  const root = bookingRootOf(this);
  hideMobileBacklink(root);
  hideSlotsContainer(root);
  showMobileDatepicker(root);
}

function backSelectSlots() {
  const root = bookingRootOf(this);

  showMobileBacklink(root);
  showSlotsContainer(root);
  showDatepicker(root);
  hideForm(root);
}

function loadBooking(e) {
  const popupOrPage = e.target;
  const root = bookingRootOnDocument(popupOrPage);
  if (!root) return;

  const datepickerEl = bookingDatepickerElem(root);
  loadBookingDatepickerWithStyle(datepickerEl);

  notifySlotsUpdate(root);

  datepickerEl.addEventListener('neocamino:booking:dateChange', onDateChangeUpdateSlots);
  datepickerEl.addEventListener('neocamino:booking:monthChange', onMonthChangeFetchSlots);

  const mobileBacklink = bookingMobileBacklink(root);
  mobileBacklink.addEventListener('click', backMobileDatepicker);

  const backlink = bookingBacklink(root);
  backlink.addEventListener('click', backSelectSlots);
}

window.loadBooking = loadBooking;
window.addEventListener('DOMContentLoaded', loadBooking);

export {
  showDatepicker,
  hideDatepicker,
  showSlotsContainer,
  hideSlotsContainer,
  showForm,
  hideForm,
  backMobileDatepicker,
  showMobileBacklink,
};
