$(function() {
  $(document).on('change', 'input.hanging-prices-boolean', function() {
    var input = $(this);
    var value = input.val();
    var otherField = input.closest('.hanging-prices').find('.hanging-prices-array');
    if (value == 'true') {
      otherField.removeClass('hidden');
    } else {
      otherField.addClass('hidden');
    }
  });
});
