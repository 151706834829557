import * as yup from 'yup';
import React from 'react';
import ServicesStep from '../components/steps/services_step';
import Step from './step';

export default class Services extends Step {
  component = () => <ServicesStep />;

  sharedValues = () => {
    return {
      emailing_service: this.diagnostic.emailing_service,
      crm_service: this.diagnostic.crm_service,
      erp_service: this.diagnostic.erp_service,
      seo_service: this.diagnostic.seo_service,
      prospect_service: this.diagnostic.prospect_service,
      publipost_service: this.diagnostic.publipost_service,
      marketing_service: this.diagnostic.marketing_service,
    };
  };

  validationSchema = () => {
    return yup.object().shape({
      emailing_service: yup.string().max(255),
      crm_service: yup.string().max(255),
      erp_service: yup.string().max(255),
      seo_service: yup.string().max(255),
      prospect_service: yup.string().max(255),
      publipost_service: yup.string().max(255),
      marketing_service: yup.string().max(255),
    })
  }
}
