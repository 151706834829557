import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

const DropArea = styled.div`
  min-height: 70px;
  border: 1px dashed grey;
  cursor: pointer;
`;

const TrashIcon = styled.i`
  position: relative;
  bottom: 25px;
  left: 80px;
  color: white;
  cursor: pointer;
  &:hover {
    color: #777777;
  }
`;

const DownloadIcon = styled.i`
  position: relative;
  bottom: 46px;
  left: 8px;
  color: white;
  cursor: pointer;
  &:hover {
    color: #777777;
  }
`;

const Preview = styled.img`
  width: 100px;
  height: 100px;
`;

const DropAreaText = styled.p`
  padding: 25px;
  position: relative;
  top: 4px;
  text-align: center;
`;

export default class MultipleUpload extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section>
        <div>
          <div className='row'>
            {this.props.pictures.map((picture, index) => (
              <div key={index} className='col-lg-2 col-md-3 col-sm-3 col-xs-4'>
                <Preview src={picture.picture.url} />
                <div
                  onClick={(event) => this.props.removePicture(event, picture.id)}
                  data-tip='Supprimer'
                  data-offset="{'top': 25, 'right': 46}"
                >
                  <TrashIcon className='fas fa-trash' />
                </div>
                <div>
                  <a
                    href={picture.picture.main.url}
                    download
                    data-tip='Télécharger'
                    data-offset="{'top': 50, 'right': 8}"
                  >
                    <DownloadIcon className='fas fa-download' />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Dropzone onDrop={(acceptedFiles) => this.props.handleMultipleUpload(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <DropArea {...getRootProps()}>
                <input {...getInputProps()} />
                <DropAreaText>
                  Glisser-déposer les images ici ou bien cliquer pour séléctionner les images
                </DropAreaText>
              </DropArea>
            </section>
          )}
        </Dropzone>
      </section>
    );
  }
}

MultipleUpload.propTypes = {
  handleMultipleUpload: PropTypes.func,
  removePicture: PropTypes.func,
  pictures: PropTypes.array,
};
