import 'core-js/modules/es.set';
import 'core-js/modules/es.map';

import React from 'react';
import ReactDOM from 'react-dom';
import EmailingCampaignFormSubjectInput from '../../components/emailing/campaign/form/subject_input';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('emailingCampaignFormSubjectInput');

  if (node !== null) {
    const data = JSON.parse(node.dataset.json);
    data.node = node;
    ReactDOM.render(<EmailingCampaignFormSubjectInput data={data} />, node);
  }
});

export default EmailingCampaignFormSubjectInput;
