
const isFirefox = /Firefox/.test(navigator.userAgent);
const isSafari = window.safari !== undefined;
const isChrome = /Chrome/.test(navigator.userAgent);

export default {
  isFirefox,
  isChrome,
  isSafari
}
