import React, { Component } from 'react';
import PrediagForm from './form';
import DiagnosticService from '../../services/diagnostic_service';

export default class PrediagPage extends Component {
  constructor(props) {
    super(props);
  }

  buildService = () => {
    return new DiagnosticService(this.props.urls);
  };

  render() {
    return (
      <PrediagForm
        isAdmin={this.props.isAdmin}
        diagnostic={this.props.diagnostic}
        showRoleToggle={this.props.showRoleToggle}
        finishURL={this.props.urls.finishURL}
        service={this.buildService()}
      />
    );
  }
}
