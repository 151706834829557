import styled from 'styled-components';

const Card = styled.div`
  user-select: none;
  border: ${(props) => (props.checked === true ? '1px solid #74bf75' : '1px solid #d4d4d4')};
  height: 170px;
  width: 165px;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: ${(props) => (props.checked === true ? '#74bf75' : '#999999')};
  margin-bottom: 30px;
`;

const Link = styled.a`
  position: relative;
  right: 20px;
  top: 7px;
  color: #babbbc;
  cursor: pointer;
  &:hover {
    color: #999999;
  }
`;

const Trash = styled.div`
  position: relative;
  bottom: ${(props) => (props.withLink === true ? '13px' : '-7px')};
  left: ${(props) => (props.withLink === true ? '5px' : '-15px')};
  color: #babbbc;
  &:hover {
    color: #999999;
  }
`;

const Ctas = styled.div`
  cursor: pointer;
`;

const AddLink = styled.p`
  cursor: pointer;
  color: #babbbc;
  width: 65px;
  &:hover {
    color: #999999;
  }
`;

export { Card, AddLink, Ctas, Trash, Link };
