import React, { Component } from 'react'
import { createPortal } from 'react-dom';

export default class Portal extends Component {
  constructor(props) {
    super(props);

    var parentContainer = this.props.parentElement.get(0);
    if (parentContainer.classList.contains('redactor_box')) {
      parentContainer = parentContainer.parentElement;
    }
    this.portalRoot = parentContainer;

    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.el);
  }

  render() {
    return createPortal(
      this.props.children,
      this.el,
    );
  }
}
