import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactModal from 'react-modal';
import RestClient from '../../../lib/rest_client';
import Browser from '../../../lib/browser';

const OptionAvatarThumb = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  position: relative;
  top: 2px;
`;

const Option = styled.div`
  &:hover {
    background-color: lightblue;
    cursor: pointer;
  }
  background-color: ${(props) => (props.isSelected === true ? '#2880b8' : 'white')};
  color: ${(props) => (props.isSelected === true ? 'white' : 'black')};
`;

const AvatarThumb = styled.img`
  z-index: ${(props) => (props.showSelect === true ? 9 : 0)};
  opacity: ${(props) => (props.isEmptyAvatar === true ? '0.5' : '1')};
  &:hover {
    cursor: pointer;
  }
`;

const OptionText = styled.span`
  position: relative;
  top: 6px;
`;

const AssignmentTextWrapper = styled.span`
  position: relative;
  bottom: 30px;
  left: 32px;
  &:hover {
    cursor: pointer;
  }
`;

const AssigneeEmail = styled.p`
  position: relative;
  bottom: 10px;
`;

const OptionRow = styled.div`
  display: inline-flex;
`;

const CloseIcon = styled.i`
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 15px;
`;

const customSelectStyles = {
  container: (styles, state) => ({
    ...styles,
    display: 'inline-block',
  }),
  control: (styles) => ({
    ...styles,
    minHeight: '25px',
    maxHeight: '25px',
    width: '0px',
    border: '0 !important',
    boxShadow: '0 !important',
  }),
  menu: (styles, state) => ({
    ...styles,
    width: 'auto',
    right: state.selectProps.singleMode ? 'initial' : '0px',
  }),
  indicatorsContainer: (styles) => ({ ...styles, maxHeight: '26px' }),
  singleValue: (styles, state) => ({
    ...styles,
    position: 'relative',
    left: '30px',
    transform: 'translateY(0px)',
  }),
};

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    height: 'auto',
  },
  overlay: {
    zIndex: 99999999,
    backgroundColor: 'rgba(70, 70, 70, 0.75)',
  },
};

const IconOption = (props) => {
  const { innerProps, data, commonProps, isSelected } = props;
  return (
    <Option {...innerProps} {...commonProps} isSelected={isSelected} style={{ textAlign: 'left', padding: '8px' }}>
      <OptionRow>
        <OptionAvatarThumb src={data.image} />
        <OptionText>{data.label}</OptionText>
      </OptionRow>
    </Option>
  );
};

const ModalBody = (props) => {
  return (
    <section className='text-center'>
      <CloseIcon onClick={props.closeModal} className='fas fa-times' />
      <h4>Vous voulez faire réaliser cette action par Neocamino ?</h4>
      <p>Notifiez votre conseiller pour qu'il vous fasse une proposition.</p>
      <article>
        <button onClick={props.agreeModal} value={1} className='btn btn-success'>
          Oui
        </button>
        &nbsp;&nbsp;
        <button onClick={props.agreeModal} value={0} className='btn btn-danger'>
          Non
        </button>
      </article>
    </section>
  );
};

const AssigneeText = (props) => {
  return (
    <AssignmentTextWrapper onClick={props.toggleSelect}>
      <p className='text-muted'>Attribué à</p>
      <AssigneeEmail>{props.assigneeEmail}</AssigneeEmail>
    </AssignmentTextWrapper>
  );
};

export default class AssigneeDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelect: false,
      selectedOption: {},
      assigneeOptions: [],
      showModal: false,
    };
    ReactModal.setAppElement('#modalRoot');
  }

  componentDidMount() {
    const assigneeOptions = JSON.parse(this.props.assigneeOptions);
    const selectedOption = assigneeOptions.find((opt) => opt.value === parseInt(this.props.assigneeId));
    this.setState({ selectedOption, assigneeOptions });
  }

  toggleSelect = () => {
    const readonlyMode = parseInt(this.props.readonlyMode);
    if (readonlyMode) {
      return;
    }
    this.setState({ showSelect: !this.state.showSelect });
  };

  setAssignee = (assignee) => {
    const { patchActionUrl } = this.props;
    const payload = assignee === null ? {} : { id: assignee.value, type: assignee.type };
    this.patchAction(patchActionUrl, payload);
  };

  patchAction = (url, payload) => {
    RestClient.patch(url, { assignee: payload }).then((res) => {
      const assignee = res.data;
      this.setState({ selectedOption: assignee, showSelect: false });

      const { refreshActionUrl } = this.props;
      if (refreshActionUrl) {
        $.get(refreshActionUrl);
      }
    });
  };

  selectChange = (e) => {
    if (e && e.type === 'Admin' && !this.props.currentAdmin) {
      this.setState({ showModal: true });
      return;
    }
    this.assignResource(e);
  };

  assignResource = (resource) => {
    this.setState({ showSelect: false, selectedOption: resource });
    this.setAssignee(resource);
  };

  handleCloseMenu = () => {
    this.setState({ showSelect: false });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  modalAgreement = (agree) => {
    if (parseInt(agree.target.value)) {
      const admin = this.state.assigneeOptions.find((res) => res.type === 'Admin');
      this.assignResource(admin);
    }
    this.closeModal();
  };

  render() {
    const avatar = (this.state.selectedOption && this.state.selectedOption.image) || this.props.emptyAvatar;
    const isEmptyAvatar = [null, undefined].includes(this.state.selectedOption) ? true : false;
    const singleMode = parseInt(this.props.singleMode);
    const assigneeEmail = (this.state.selectedOption && this.state.selectedOption.label) || 'Personne';
    return (
      <span>
        <AvatarThumb
          src={avatar}
          className='img-avatar-circle'
          onClick={this.toggleSelect}
          showSelect={this.state.showSelect}
          isEmptyAvatar={isEmptyAvatar}
          isChrome={Browser.isChrome}
          isSafari={Browser.isSafari}
          singleMode={singleMode}
        />
        {this.state.showSelect && (
          <Select
            options={this.state.assigneeOptions}
            styles={customSelectStyles}
            menuIsOpen={true}
            components={{ Option: IconOption, DropdownIndicator: () => null }}
            placeholder=''
            value={this.state.selectedOption}
            onChange={this.selectChange}
            onBlur={this.handleCloseMenu}
            autoFocus={true}
            isSearchable={false}
            singleMode={singleMode}
            isClearable={false}
          />
        )}
        {singleMode === 1 && <AssigneeText toggleSelect={this.toggleSelect} assigneeEmail={assigneeEmail} />}
        {this.state.showModal && (
          <ReactModal
            isOpen={this.state.showModal}
            style={customModalStyles}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={false}
          >
            <ModalBody closeModal={this.closeModal} agreeModal={this.modalAgreement} />
          </ReactModal>
        )}
      </span>
    );
  }
}

AssigneeDropdown.propTypes = {
  assigneeOptions: PropTypes.string,
  assigneeId: PropTypes.string,
  patchActionUrl: PropTypes.string,
  refreshActionUrl: PropTypes.string,
  emptyAvatar: PropTypes.string,
  singleMode: PropTypes.string,
  readonlyMode: PropTypes.string,
};

IconOption.propTypes = {
  innerProps: PropTypes.object,
  data: PropTypes.object,
  commonProps: PropTypes.object,
};

ModalBody.propTypes = {
  closeModal: PropTypes.func,
  agreeModal: PropTypes.func,
};

AssigneeText.propTypes = {
  toggleSelect: PropTypes.func,
  assigneeEmail: PropTypes.string,
};
