import styled from 'styled-components';

const CardText = styled.div`
  position: relative;
  top: 5px;
  padding: 0 5px 0 5px;
`;

const Card = styled.div`
  border: ${(props) => (props.checked === true ? '1px solid #74bf75' : '1px solid #d4d4d4')};
  height: 170px;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: ${(props) => (props.checked === true ? '#74bf75' : '#999999')};
  margin-bottom: 30px;
  user-select: none;
`;

const CardIcon = styled.div`
  padding-top: 15px;
`;

const CardCheckIcon = styled.i`
  position: absolute;
  top: 28px;
  right: 7px;
`;

export { Card, CardIcon, CardText, CardCheckIcon };
