import * as yup from 'yup';
import React from 'react';
import PrediagHelpers from '../helpers';
import CustomersAndOffersStep from '../components/steps/customers_and_offers_step';
import Step from './step';

export default class CustomersAndOffers extends Step {
  component = () => <CustomersAndOffersStep />;

  sharedValues = () => {
    return {
      offer_synthesis: this.diagnostic.offer_synthesis,
      customers_location: PrediagHelpers.checkedValueForForm(this.diagnostic.customers_location),
      customers_location_description: this.diagnostic.customers_location_description,
      marketing_canvas_attributes: {
        id: this.diagnostic.marketing_canvas.id,
        promise: this.diagnostic.marketing_canvas.promise,
      },
      account_attributes: {
        id: this.diagnostic.account_id,
        offer_values: PrediagHelpers.fieldValuesSplit(this.diagnostic.account.offer, this.defaultEmptyFieldValues),
        websites_competing_values: PrediagHelpers.fieldValuesSplit(
          this.diagnostic.account.websites_competing,
          this.defaultEmptyFieldValues
        ),
        buyer_personas_attributes: this.diagnostic.buyer_personas,
      },
    };
  };

  adminValues = () => {
    return {
      service_sells_focus: this.diagnostic.service_sells_focus,
      customers_focus:     this.diagnostic.customers_focus
    }
  };

  defaultEmptyFieldValues = [{ name: null }, { name: null }, { name: null }];

  validationSchema = () => {
    const buyerPersonasSchema = yup.object().shape({
      name: yup.string().max(255),
    });

    return yup.object().shape({
      account_attributes: yup.object().shape({
        buyer_personas_attributes: yup.array().of(buyerPersonasSchema),
      }),
    });
  };
}
