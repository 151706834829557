import { merge } from 'lodash';

export default class Step {
  constructor(diagnostic, account, novice, isAdmin) {
    this.diagnostic = diagnostic;
    this.account = account;
    this.isNovice = novice;
    this.isAdmin = isAdmin;
  }

  values = () => {
    var valuesObject = { ...this.sharedValues() }

    if (this.isAdmin) {
      valuesObject = merge(valuesObject, this.adminValues());
      valuesObject = merge(valuesObject, this.commentValues());
    }
    valuesObject = merge(valuesObject, this.noviceOrAdvancedValues());

    return valuesObject
  };

  setDiagnosticObject = (newObject, oldObject, suffix) => {
    Object.keys(oldObject).map((key) => {
      if (oldObject[key] && oldObject[key].constructor === Object) {
        this.setDiagnosticObject(newObject, oldObject[key], suffix);
      } else {
        if (key.includes('_attributes')) {
          key = key.replace('_attributes', '')
        }
        newObject[key + suffix] = eval("diagnostic." + key + suffix)
      }
    });
  };

  noviceOrAdvancedValues = () => {
    return this.isNovice ? this.noviceValues() : this.advancedValues();
  };

  sharedValues = () => {
    return {};
  };
  noviceValues = () => {
    return {};
  };
  advancedValues = () => {
    return {};
  };
  adminValues = () => {
    return {};
  };

  commentValues = () => {
    var commentValuesObject = { admin: {} };
    var sharedValuesObject = {
      ...this.adminValues(),
      ...this.sharedValues()
    };

    sharedValuesObject = merge(sharedValuesObject, this.noviceOrAdvancedValues());

    this.setDiagnosticObject(commentValuesObject, sharedValuesObject, "_comment");

    return commentValuesObject;
  };

  validationSchema = () => null;
}
