import React, { Component } from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  height: 100%;
  width: ${ props => props.fieldWidth }px;
  color: white;
  display: inline-block;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export default class Field extends Component {
  constructor(props) {
    super(props);
  }

  onInput = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <StyledInput
        maxLength="200"
        ref="fieldInput"
        onChange={ this.onInput }
        value={ this.props.searchText }
        fieldWidth={ this.props.fieldWidth }
        name={ this.props.fieldName }
        placeholder={ this.props.fieldPlaceholder }
        autoComplete="off"
      />
    )
  }
}
