import "core-js/modules/es.set";
import "core-js/modules/es.map";

import React from "react";
import ReactDOM from "react-dom";
import WebsiteBrief from "../../components/website_brief";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById('websiteBrief');

  if (node !== null) {
    const {
      patchUrl,
      websiteBrief,
      websiteTypeOptions,
      websiteEditorOptions,
      hostingOptions,
      additionalFeaturesOptions,
      projectTypeOptions,
      baseFeaturesOptions,
      pictures
    } = node.dataset;
    ReactDOM.render(
      <WebsiteBrief
        patchUrl={patchUrl}
        websiteBrief={JSON.parse(websiteBrief)}
        websiteTypeOptions={JSON.parse(websiteTypeOptions)}
        websiteEditorOptions={JSON.parse(websiteEditorOptions)}
        hostingOptions={JSON.parse(hostingOptions)}
        additionalFeaturesOptions={JSON.parse(additionalFeaturesOptions)}
        baseFeaturesOptions={JSON.parse(baseFeaturesOptions)}
        projectTypeOptions={JSON.parse(projectTypeOptions)}
        pictures={JSON.parse(pictures)}
      />,
      node
    );
  }
});
