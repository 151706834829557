import React, { Component } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const StyledIcon = styled.span`
  color: white;

  font-size: 14px;
  line-height: 20px;
  text-align: center;

  width: ${(props) => props.iconWidth}px;
  padding: 15px;

  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => lighten(0.1, props.bgColor)};
  }
`;

export default class AppSearchBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledIcon
        onClick={this.props.onClick}
        className=''
        iconWidth={this.props.iconWidth}
        bgColor={this.props.bgColor}
        rel='tooltip'
        title={this.props.iconTitle}
        data-placement='bottom'
      >
        <i className='fas fa-search' />
      </StyledIcon>
    );
  }
}
