export default {
  events: {
    CHART_DATECHANGE: 'analytics:chart:dateChange',
    CHART_REFRESH: 'analytics:chart:refresh',
    CHART_HIDE: 'analytics:chart:hide',
    METRICS_CHANGE: 'analytics:metrics:change',
    METRICS_REFRESH: 'analytics:metrics:refresh',
  },
  selectors: {
    BASE_CONTAINER: '.analytics-component',
    ASYNC_CHART_CONTAINER: '.analytics__async-chart-container-component',
    ASYNC_METRICS_CONTAINER: '.analytics__async-metrics-container-component',
    METRIC: '.analytics__metric',
    METRIC_BTN: '.analytics__metric__btn',
    ACTIVE_METRIC_BTN: '.analytics__metric__btn.active',
  }
}
