import GutterAnnotator from '../../services/gutter_annotator';

document.addEventListener('DOMContentLoaded', () => {
  const annotatorTargetId = 'social-brief--annotator-target';
  const node = document.getElementById(annotatorTargetId);

  if (node == null) {
    return;
  }

  const settings = socialBriefAnnotatorSettings;
  const formClass = '.social-brief-form';
  const cocoonParentSelector = false;

  let annotator = new GutterAnnotator(node, settings, formClass, cocoonParentSelector);

  annotator.render();
});
