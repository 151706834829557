import "core-js/modules/es.array.iterator";

import React from "react";
import ReactDOM from "react-dom";
import BrandStyleGuide from "../../components/brand_style_guide";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("brandStyleGuide");

  if (node !== null) {
    const {
      brandStyleGuide,
      patchUrl,
      iconsStyleOptions,
      picturesStyleOptions,
      pictures
    } = node.dataset;
    ReactDOM.render(
      <BrandStyleGuide
        patchUrl={patchUrl}
        brandStyleGuide={JSON.parse(brandStyleGuide)}
        pictures={JSON.parse(pictures)}
        iconsStyleOptions={JSON.parse(iconsStyleOptions)}
        picturesStyleOptions={JSON.parse(picturesStyleOptions)}
      />,
      node
    );
  }
});
