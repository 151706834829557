import React from 'react';
import Step from '../step';
import { NcForm, NcInput } from '../../../../shared_components/neocamino/inputs';
import { Button } from 'react-bootstrap';

export default function FinalStep({
  values,
  isAdmin,
  finishFn,
  onSubmitFn,
  onAutoSaveFn,
  validationSchema,
  stepID,
  ...rest
}) {
  const onSubmit = (data) => {
    onSubmitFn(stepID, data).then(() => {
      finishFn();
    });
  };

  const onAutoSave = (data) => {
    onAutoSaveFn(stepID, data);
  };

  return (
    <Step name='Conclusion' stepID={stepID} {...rest}>
      <NcForm onSubmit={onSubmit} onAutoSave={onAutoSave} defaultValues={values} validationSchema={validationSchema}>
        <NcInput name='neocamino_expectation' label="Qu'attendez-vous de Neocamino ?" componentClass='textarea' />
        <NcInput name='comments' label='Avez-vous des commentaires ?' componentClass='textarea' />

        <hr />

        <div>
          <p>Vous êtes arrivé(e) à la fin de ce questionnaire préliminaire.</p>

          <p>
            En cliquant sur le bouton ci-dessous, les informations que vous avez saisies vont nous être communiquées.
          </p>

          <p>N'hésitez pas à revenir sur vos réponses et à les compléter avant notre premier rendez-vous.</p>

          <p>Merci pour votre temps et à très bientôt pour échanger ensemble !</p>

          <p>Votre conseiller(e)</p>
        </div>

        <hr />

        <div className='text-right m-t-sm'>
          <Button type='submit' variant='success'>
            Envoyer à mon conseiller
          </Button>
        </div>
      </NcForm>
    </Step>
  );
}
