import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .media-header {
    margin-bottom: 0.4em;

    .media-heading {
      margin: 0;
    }

    em {
      font-size: 12px;
    }
  }
  .media-text {
    word-break: break-word;
    white-space: break-spaces;
  }

  ${(props) =>
    props.isNote && {
      backgroundColor: '#feedaf',
      padding: '5px',
      borderRadius: '4px',
    }}
`;

const DeletionBtn = styled.button`
  font-size: 12px;
  line-height: 12px;
  margin-top: -2px;
  padding: 0;
`;

export default class Annotation extends Component {
  constructor(props) {
    super(props);
  }

  annotationDate = () => {
    return moment(this.props.createdAt).format('DD/MM/YYYY H:mm');
  };

  deleteAnnotation = () => {
    return this.props.deleteFn(this.props.id);
  };

  deletionLink = () => {
    if (this.props.canDelete) {
      return (
        <DeletionBtn className='btn btn-link' onClick={this.deleteAnnotation}>
          Supprimer
        </DeletionBtn>
      );
    }
  };

  mediaHeading = () => {
    let pin;

    if (this.props.isNote) {
      pin = (
        <span>
          <i className='fas fa-eye-slash' />
          &nbsp;
        </span>
      );
    }

    return (
      <h5 className='media-heading'>
        {pin}
        {this.props.username}
      </h5>
    );
  };

  render() {
    return (
      <Container className='media' isNote={this.props.isNote}>
        <div className='media-left'>
          <img width='32' height='32' className='media-object' src={this.props.photo} alt={this.props.username} />
        </div>
        <div className='media-body'>
          <div className='media-header'>
            {this.mediaHeading()}
            <em>{this.annotationDate()}</em>

            {this.deletionLink()}
          </div>

          <div className='media-text'>{this.props.text}</div>
        </div>
      </Container>
    );
  }
}
