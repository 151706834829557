import React from 'react';
import { NcButton, NcForm, NcInput } from '../../../shared_components/neocamino/inputs';
import Step from './step';

export default function StepForm({
  onSubmitFn,
  onAutoSaveFn,
  validationSchema,
  stepID,
  values,
  children,
  saving,
  isAdmin,
  ...rest
}) {
  const onSubmit = (data) => {
    onSubmitFn(stepID, data);
  };
  const onAutoSave = (data) => {
    onAutoSaveFn(stepID, data);
  };

  const commentName = (children) => {
    var name = children.props.name

    if (name.includes('[')) {
      name = name.match(/\[(.*?)\]/)[1]
    }

    return name + '_comment'
  }

  const childrenWithComments = (children) => {
    if (children.props.type && children.type !== 'hidden' &&
      ['text', 'url', 'fieldArray', 'textarea', 'buyerPersonas', 'radio', 'customNumber', 'select'].includes(children.props.type)) {
      return (
        <>
          <div className='row'>
            <div className='col-md-8'>
              {children}
            </div>
            <div className='col-md-4'>
              <NcInput
                  key={commentName(children)}
                  type='text'
                  name={commentName(children)}
                  label='Commentaire'
                  componentClass='textarea'
                  className="admin"
              />
            </div>
          </div>
        </>
      );
    } else {
      return children
    }
  }

  const parseIfComponentForComments = (elem, index) => {
    var conditionElem = elem.props.condition ? elem.props.then : elem.props.else

    if (!conditionElem) return

    if (conditionElem.props.children) {
      return conditionElem.props.children.map(elem => {
        return childrenWithComments(elem, index)
      })
    } else {
      return childrenWithComments(conditionElem, index)
    }
  };

  const formatFormForAdmins = (childrens) => {
    return childrens.map((elem, index) => {
      if (elem.props.then || elem.props.else) {
        return parseIfComponentForComments(elem, index)
      } else  {
        return childrenWithComments(elem, index)
      }
    }).flat();
  }

  return (
    <Step stepID={stepID} isAdmin={isAdmin} {...rest}>
      <NcForm onSubmit={onSubmit} onAutoSave={onAutoSave} defaultValues={values} validationSchema={validationSchema}>
        { isAdmin ? formatFormForAdmins(children) : children }

        <div className='form-actions'>
          {saving ? (
            <NcButton text='Sauvegarde en cours…' variant='primary' disabled />
          ) : (
            <NcButton text='Continuer' variant='primary' />
          )}
        </div>
      </NcForm>
    </Step>
  );
}
