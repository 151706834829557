import analytics from 'analytics/shared';

// *** Notifiers ***
var refreshMetrics = function(analyticsContainer, dateRange, currentScope) {
  const metricsContainer = analyticsContainer
    .querySelector(analytics.selectors.ASYNC_METRICS_CONTAINER);

  const event = new CustomEvent(analytics.events.METRICS_REFRESH, {
    detail: {
      from: dateRange.from,
      to: dateRange.to,
      scope: currentScope
    }
  });

  metricsContainer.dispatchEvent(event);
}

var NotifyChart = function(analyticsContainer, dateRange) {
  const chartContainer = analyticsContainer
    .querySelector(analytics.selectors.ASYNC_CHART_CONTAINER);

  const event = new CustomEvent(analytics.events.CHART_DATECHANGE, {
    detail: dateRange
  });

  chartContainer.dispatchEvent(event);
}

var refreshChart = function(chartContainer, metric) {
  const payload = { scope: metric.dataset.analyticsScope };

  const event = new CustomEvent(analytics.events.CHART_REFRESH, {
    detail: payload
  });

  chartContainer.dispatchEvent(event);
}

var hideChart = function(chartContainer) {
  const event = new CustomEvent(analytics.events.CHART_HIDE);

  chartContainer.dispatchEvent(event);
}

// *** Helpers ***
var getActiveMetric = function (metricContainer) {
  const el = metricContainer.querySelector(`${analytics.selectors.METRIC}:has(> ${analytics.selectors.ACTIVE_METRIC_BTN})`);
  return el;
}

// *** Listeners ***
var onDateChange = function(e) {
  const dateRange = e.detail;

  const analyticsContainer = e.target
    .closest(analytics.selectors.BASE_CONTAINER);

  if (!analyticsContainer) return;

  const selectedMetric = getActiveMetric(analyticsContainer);

  var currentScope;

  if (selectedMetric) {
    currentScope = selectedMetric.dataset.analyticsScope;
  }

  refreshMetrics(analyticsContainer, dateRange, currentScope);
  NotifyChart(analyticsContainer, dateRange);
}

var onSelectedChange = function(e) {
  var metric = e.detail;
  metric &&= e.detail.metric;
  metric ||= e.target.closest(analytics.selectors.METRIC);

  const activating = metric.querySelector(analytics.selectors.METRIC_BTN).classList.contains('active');

  const analyticsContainer = metric
    .closest(analytics.selectors.BASE_CONTAINER);

  const chartContainer = analyticsContainer
    .querySelector(analytics.selectors.ASYNC_CHART_CONTAINER);

  if (activating) {
    refreshChart(chartContainer, metric);
  } else {
    hideChart(chartContainer);
  }
}

var hookTimeControlEvents = function() {
  const els = document.querySelectorAll('.analytics__time-control-component');

  els.forEach((timeControl) => {
    timeControl.addEventListener('analytics:dateRangeChange', onDateChange);
  });
}

var hookMetricsEvents = function() {
  const els = document.querySelectorAll(analytics.selectors.ASYNC_METRICS_CONTAINER);

  els.forEach((metricsContainer) => {
    metricsContainer.addEventListener('click', onSelectedChange);
    metricsContainer.addEventListener(analytics.events.METRICS_CHANGE, onSelectedChange);
  });
}

var hookEvents = function() {
  hookTimeControlEvents();
  hookMetricsEvents();
}

document.addEventListener('DOMContentLoaded', hookEvents);
