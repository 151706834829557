
const generateUuid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

const capitalize = str => {
  return str[0].toUpperCase() + str.slice(1);
};

const camelToSnakeCase = str => {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export { generateUuid, capitalize, camelToSnakeCase };
