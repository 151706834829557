import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import { Link, Trash, Ctas, AddLink } from './shared/styled_components';

const InputWrapper = styled.div`
  margin-top: 5px;
`;

const Input = styled.input`
  margin-bottom: 5px;
`;

const SubSectionTitle = styled.p`
  position: relative;
  top: 10px;
`;

const SubSectionBody = styled.p`
  position: relative;
  top: 15px;
`;

const MultipleDynamicInput = ({
  register,
  handleChange,
  control,
  inputName,
  attributes,
  withLink = false,
  currentState,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputName,
  });

  useEffect(() => {
    hookAutosize();
  }, []);

  const removeDomain = (index) => {
    remove(index);
    setTimeout(() => {
      handleChange();
    }, 100);
  };

  const addDomain = (attributes) => {
    let payload = {
      [attributes.title]: '',
      [attributes.body]: '',
    };
    append(payload);
    setTimeout(() => {
      handleChange();
      hookAutosize();
    }, 100);
  };

  const getUrl = (currentState, field) => {
    if (currentState === undefined) {
      return '';
    }
    const element = currentState.find((el) => el.id === field.id);
    return element === undefined ? '' : element.title;
  };

  return (
    <div>
      {fields.map((field, index) => (
        <div className='row' key={field.id}>
          <article className='col-xs-3 text-right text-muted'>
            <SubSectionTitle>{attributes.title}</SubSectionTitle>
            <SubSectionBody>{attributes.body}</SubSectionBody>
          </article>
          <article className='col-xs-9'>
            <div>
              <InputWrapper className='row' withLink={withLink}>
                <div className='col-xs-11'>
                  <Input
                    type='text'
                    name={`${inputName}[${index}].title`}
                    ref={register({})}
                    defaultValue={field.title}
                    className='form-control grey-color-input'
                    autoComplete='off'
                    onBlur={handleChange}
                  />
                  <textarea
                    name={`${inputName}[${index}].body`}
                    ref={register({})}
                    defaultValue={field.body}
                    className='form-control grey-color-input autosize'
                    autoComplete='off'
                    onBlur={handleChange}
                  />
                  <input
                    name={`${inputName}[${index}].id`}
                    defaultValue={field.id}
                    ref={register({})}
                    className='hidden'
                  />
                </div>
                <Ctas className='col-xs-1'>
                  {withLink && (
                    <Link href={`http://${getUrl(currentState, field)}`} target='_blank'>
                      <i className='fas fa-external-link-alt'></i>
                    </Link>
                  )}
                  <Trash onClick={() => removeDomain(index)} withLink={withLink}>
                    <i className='fas fa-trash'></i>
                  </Trash>
                </Ctas>
              </InputWrapper>
            </div>
          </article>
        </div>
      ))}
      <div className='row'>
        <article className='col-xs-3 text-right text-muted'></article>
        <article className='col-xs-9'>
          <AddLink onClick={() => addDomain(attributes)}>+ Ajouter</AddLink>
        </article>
      </div>
    </div>
  );
};

export { MultipleDynamicInput };
