import React, { Component } from 'react';
import styled from 'styled-components';

const Anchor = styled.a`
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  text-decoration: none;

  background-color: ${(props) => props.color};
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;

  position: relative;

  &:hover {
    color: white;
    text-decoration: none;
  }

  &:before {
    box-shadow: inset 0 0 0 32px;
    color: ${(props) => props.color};
    content: '';
    height: 8px;
    left: -4px;
    top: 12px;
    position: absolute;
    transform: rotate(-45deg);
    width: 8px;
  }

  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const Counter = styled.span`
  line-height: ${(props) => (props.value > 0 ? props.size : 30)}px;
  display: block;
`;

export default class Bubble extends Component {
  constructor(props) {
    super(props);
  }

  counter = () => {
    return this.props.counter;
  };

  render() {
    return (
      <Anchor
        visible={this.props.visible}
        color={this.props.color}
        size={this.props.size}
        onClick={this.props.clickFn}
      >
        <Counter size={this.props.size} value={this.counter()}>
          {this.counter() ? (
            this.counter()
          ) : (
            <i>
              <i className='fa fa-comment'></i>
            </i>
          )}
        </Counter>
      </Anchor>
    );
  }
}
