import { Row, Col } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import { Card } from './shared/styled_components';

const CardText = styled.div`
  position: relative;
  top: 5px;
  padding: 12px 5px 0 5px;
`;

const CardIcon = styled.div`
  padding-top: 30px;
`;

const CardCheckIcon = styled.i`
  position: absolute;
  top: 57px;
  right: 8px;
`;

const MultipleChoice = ({ options, icons, selected, register, inputName, handleChange }) => {
  return (
    <Row>
      {options.map((option, index) => (
        <Col md={4} sm={6} key={index}>
          <label htmlFor={option}>
            <Card checked={selected.includes(option)}>
              <CardIcon>
                <i className={icons[option] + ' fa-4x'}></i>
              </CardIcon>
              <CardText>
                {selected.includes(option) && <CardCheckIcon className='fas fa-check-square'></CardCheckIcon>}
                <p>{option}</p>
              </CardText>
            </Card>
          </label>
          <input
            name={`${inputName}[${index}]`}
            id={option}
            type='checkbox'
            className='hidden'
            value={option}
            ref={register}
            onChange={handleChange}
            defaultChecked={selected.includes(option)}
          />
        </Col>
      ))}
    </Row>
  );
};

export { MultipleChoice };
