import React, { Component } from 'react';
import ModalBody from './modal_body';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export default class ContactsTableColumnModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: null });
  };

  render() {
    return (
      <span>
        <a className='btn btn-default me-1' onClick={this.showModal} rel='tooltip' title='Modifier les colonnes'>
          <i className='fas fa-columns'></i>
        </a>
        <div className='static-modal'>
          <Modal show={!!this.state.showModal} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Choisissez les colonnes à afficher</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalBody
                translations={this.props.data.translations}
                contactFields={this.props.data.contact_fields}
                columns={this.props.data.columns}
                patchTableSettingEndpoint={this.props.data.patch_table_setting_endpoint}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeModal}>Fermer</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </span>
    );
  }
}

ContactsTableColumnModal.propTypes = {
  data: PropTypes.object,
};
